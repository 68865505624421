import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { toast } from "react-toastify";
import { updatePromotion } from "../../services/userServices";

const UpdatePromotion = () => {
  const { pi_id } = useParams();
  const [promotion, setPromotion] = useState([]);
  const [errors, setErrors] = useState([]);

  const [promotionImg, setPromotionImg] = useState(null);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      pi_title: promotion.pi_title || "",
      pi_subtitle: promotion.pi_subtitle || "",
      pi_description: promotion.pi_description || "",
      pi_id: promotion.pi_id || "",
    },
  });

  useEffect(() => {
    setValue("pi_title", promotion.pi_title || "");
    setValue("pi_subtitle", promotion.pi_subtitle || "");
    setValue("pi_description", promotion.pi_description || "");
    setValue("pi_id", promotion.pi_id || "");
  }, [promotion]);

  useEffect(() => {
    apiClient
      .get(`/single-promotional/${pi_id}`)
      .then((response) => setPromotion(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    updatePromotion(formData, promotionImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/product-promotions");
          //   navigate("/banner-slider");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating banner slider.");
      });
  };
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Edit Promotions</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="pi_title"
                            className="form-control"
                            id="pi_title"
                            maxLength={25}
                            defaultValue={promotion.pi_title}
                            required
                            {...register("pi_title")}
                          />
                          <input
                            type="hidden"
                            name="pi_id"
                            className="form-control"
                            id="pi_id"
                            required
                            defaultValue={promotion.pi_id}
                            {...register("pi_id")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Subtitle</label>
                          <input
                            type="text"
                            name="pi_subtitle"
                            className="form-control"
                            id="pi_subtitle"
                            maxLength={50}
                            defaultValue={promotion.pi_subtitle}
                            required
                            {...register("pi_subtitle")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <input
                            type="text"
                            name="pi_description"
                            className="form-control"
                            id="pi_description"
                            maxLength={50}
                            defaultValue="You Kitchen Partner"
                            required
                            {...register("pi_description")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12 border">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Promotion Image</label>
                              <input
                                className="form-control"
                                type="file"
                                id="upload_image1"
                                name="upload_image1"
                                onChange={(e) =>
                                  setPromotionImg(e.target.files[0])
                                }
                                required
                              />
                              <div
                                id="uploaded_image1"
                                className="ml-auto pt-2"
                              />
                              <div className="col-md-12">
                                <div id="previous_image" className="my-3">
                                  <img
                                    src={`${IMAGE_BASE_URL}/${
                                      promotion && promotion.pi_img
                                    }`}
                                    height={100}
                                    className="img-rounded"
                                  />
                                </div>
                              </div>
                              <div
                                className="modal"
                                id="image_modal1"
                                data-backdrop="static"
                                data-keyboard="false"
                                tabIndex={-1}
                                role="dialog"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-body">
                                      <div className="col-lg-12">
                                        <div id="image_demo1" />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-primary crop_image1"
                                      >
                                        Save and upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdatePromotion;
