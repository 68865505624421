import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";

const ProductsSubCategories = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [errors, setErrors] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    apiClient
      .get("/get-product-sub-cate")
      .then((response) => setSubCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const deactivateSubCategory = (subCategoryId) => {
    apiClient
      .post(`/update-product-sub-cate-status?s_cat_id=${subCategoryId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchCategories();
      })
      .catch((error) => {
        toast.error("Error try again!:", error);
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = subCategories.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(subCategories.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handleClick(i)}>
            {i}
          </button>
        </li>
      );
    }
    return buttons;
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3">
              <NavLink
                to="/add-product-subcategory"
                className="btn btn-success btn-custom btn-sm"
              >
                <i className="fas fa-plus" /> Add New Subcategory
              </NavLink>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-md-9 align-items-center">
                      <h3 className="card-title m-1"> Product Subcategories</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Subcategory</th>
                          <th>Category</th>
                          <th>Status</th>
                          <th>Date / Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((subCategory) => (
                            <tr key={subCategory.s_cat_id}>
                              <td className="project-actions">
                                <Link
                                  to={`/update-product-subcat/${subCategory.s_cat_id}`}
                                  className="btn btn-info btn-sm btn-custom m-1"
                                  // href={item.action.edit}
                                >
                                  <i className="fas fa-pencil-alt"></i> Edit
                                </Link>
                                {subCategory.is_deleted < 1 ? (
                                  <button
                                    className="btn btn-danger btn-custom btn-sm m-1"
                                    onClick={() =>
                                      deactivateSubCategory(
                                        subCategory.s_cat_id
                                      )
                                    }
                                  >
                                    <i className="fas fa-trash"></i> Deavtive
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-custom btn-sm m-1"
                                    onClick={() =>
                                      deactivateSubCategory(
                                        subCategory.s_cat_id
                                      )
                                    }
                                  >
                                    <i className="fas fa-plus"></i> Activate
                                  </button>
                                )}
                              </td>
                              <td>{subCategory.p_sub_category_name}</td>
                              <td>{subCategory.category.p_category_name}</td>
                              <td>
                                {" "}
                                {subCategory.is_deleted !== 1 && (
                                  <span className="badge badge-success p-2">
                                    Active
                                  </span>
                                )}
                                {subCategory.is_deleted !== 0 && (
                                  <span className="badge badge-danger p-2">
                                    Deactive
                                  </span>
                                )}
                              </td>
                              <td>{subCategory.timestamp}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                  <nav>
                    <ul className="pagination justify-content-center">
                      {renderPaginationButtons()}
                    </ul>
                  </nav>
                </div>
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsSubCategories;
