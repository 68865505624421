import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import apiClient from "../utils/api-client";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateSitemap } from "../services/userServices";

const UpdateSitemap = () => {
  const { s_id } = useParams();
  const [allSitemap, setAllSitemap] = useState([]);
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      s_id: allSitemap.s_id || "",
      s_title: allSitemap.s_title || "",
      s_url: allSitemap.s_url || "",
    },
  });

  useEffect(() => {
    setValue("s_id", allSitemap.s_id || "");
    setValue("s_title", allSitemap.s_title || "");
    setValue("s_url", allSitemap.s_url || "");
  }, [allSitemap]);

  useEffect(() => {
    apiClient
      .get(`/get-sitemap/${s_id}`)
      .then((response) => setAllSitemap(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    updateSitemap(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/sitemap");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating sitemap.");
      });
  };

  console.log("allSitemap:", allSitemap);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info card-outline">
                <div className="card-header ">
                  <div className="row">
                    <div className="col-sm-8">
                      <h3 className="card-title">Sitemap Edit</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <table
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>URL</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="hidden"
                                className="form-control"
                                name="s_title"
                                defaultValue={allSitemap.s_title}
                                {...register("s_id")}
                              />
                              <input
                                type="text"
                                className="form-control"
                                name="s_title"
                                defaultValue={allSitemap.s_title}
                                {...register("s_title")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="s_url"
                                defaultValue={allSitemap.s_url}
                                {...register("s_url")}
                              />
                            </td>
                            <td>
                              <input
                                type="submit"
                                defaultValue="Update"
                                className="btn btn-success btn-custom"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateSitemap;
