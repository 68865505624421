import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../assets/brand/logo_icon.png";
import MenuItem from "./MenuItem";

const MenuSidebar = () => {
  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTabletView = window.innerWidth <= 768;
      const navLinks = document.querySelectorAll(".all-links");
      navLinks.forEach((link) => {
        if (isMobileOrTabletView) {
          link.setAttribute("data-widget", "pushmenu");
        } else {
          link.removeAttribute("data-widget");
        }
      });
    };

    handleResize(); // Call the function initially
    window.addEventListener("resize", handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Remove the event listener when component unmounts
    };
  }, []);

  return (
    <>
      <aside className="main-sidebar collapsible sidebar-dark-primary elevation-4">
        <Link to="/" className="brand-link ">
          <img
            src={logo}
            alt="Bakumia-Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">Bakumia Store</span>
        </Link>
        <div className="sidebar navbar-nav-scroll">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <MenuItem
                title="Dashboard"
                icon="nav-icon fas fa-tachometer-alt"
                link="/"
              />

              <MenuItem
                title="Sitemap"
                icon="nav-icon fas fa-tachometer-alt"
                link="/sitemap"
              />

              <li className="nav-item">
                <Link className="nav-link">
                  <i className="nav-icon fas fa-columns"></i>
                  <p>
                    Roles Management
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview ">
                  <MenuItem
                    title="Roles"
                    icon="far fa-circle nav-icon"
                    link="/roles"
                  />
                  <MenuItem
                    title="Assign Roles"
                    icon="far fa-circle nav-icon"
                    link="/assign-roles"
                  />
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p>
                    Users
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </Link>
                <ul className="nav nav-treeview ">
                  <MenuItem
                    title="Users"
                    icon="far fa-circle nav-icon"
                    link="/users"
                  />
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link">
                  <i className="nav-icon fas fa-th"></i>
                  <p>
                    Products
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview ">
                  <MenuItem
                    title="Products"
                    icon="far fa-circle nav-icon"
                    link="/products"
                  />
                  <MenuItem
                    title="Products Catgory"
                    icon="far fa-circle nav-icon"
                    link="/products-category"
                  />
                  <MenuItem
                    title="Sub Categories"
                    icon="far fa-circle nav-icon"
                    link="/subcategories"
                  />
                  <MenuItem
                    title="Banner Slider"
                    icon="far fa-circle nav-icon"
                    link="/banner-slider"
                  />
                  <MenuItem
                    title="Product Promotions"
                    icon="far fa-circle nav-icon"
                    link="/product-promotions"
                  />
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link">
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Blogs
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview ">
                  <MenuItem
                    title="All Blogs"
                    icon="far fa-circle nav-icon"
                    link="/blogs"
                  />
                  <MenuItem
                    title="Add New Blog"
                    icon="far fa-circle nav-icon"
                    link="/new-blog"
                  />
                  <MenuItem
                    title="Blog Categories"
                    icon="far fa-circle nav-icon"
                    link="/blog-categories"
                  />
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link">
                  <i className="nav-icon fas fa-bell"></i>
                  <p>
                    Notifications
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <MenuItem
                    title="Push Notifications"
                    icon="far fa-circle nav-icon"
                    link="/push-notifications"
                  />
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link">
                  <i className="nav-icon fas fa-shopping-cart"></i>
                  <p>
                    Orders
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <MenuItem
                    title="All Orders"
                    icon="far fa-circle nav-icon"
                    link="/orders"
                  />
                  <MenuItem
                    title="Cities"
                    icon="far fa-circle nav-icon"
                    link="/cities"
                  />
                  <MenuItem
                    title="Shipping Charges"
                    icon="far fa-circle nav-icon"
                    link="/shipping-charges"
                  />
                  <MenuItem
                    title="Reviews"
                    icon="far fa-circle nav-icon"
                    link="/reviews"
                  />
                </ul>
              </li>

              <MenuItem
                title="Promo Codes"
                icon="nav-icon fas fa-code"
                link="/promo-codes"
              />

              {/* <MenuItem
                title="Referral Users"
                icon="nav-icon far fa-handshake"
                link="/referral-users"
              /> */}

              <MenuItem
                title="Logout"
                icon="nav-icon fas fa-lock-open"
                link="/logout"
              />
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default MenuSidebar;
