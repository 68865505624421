import apiClient from "../utils/api-client";

export function addProduct(product, peoductImg) {
  const body = new FormData();
  body.append("keywords", product.keywords);
  body.append("meta_title", product.meta_title);
  body.append("meta_desc", product.meta_desc);
  body.append("product_name", product.product_name);
  body.append("alt_content", product.alt_content);
  body.append("product_details", product.product_details);
  body.append("product_image", peoductImg);
  body.append("in_stock", product.in_stock);
  body.append("is_featured", product.is_featured);
  body.append("category_id_fk", product.category_id_fk);
  body.append("subcat_id_fk", product.subcat_id_fk);
  body.append("is_youtube_video", product.is_youtube_video);
  body.append("youtube_video_url", product.youtube_video_url);
  body.append("youtube_video_id", product.youtube_video_id);
  return apiClient.post("/add-product", body);
}

export function updateProductDetails(productDetails) {
  return apiClient.post("/update-product", productDetails);
}

export function updateProductImg(product_id, productImg) {
  const body = new FormData();
  body.append("product_id", product_id);
  body.append("product_image", productImg);
  return apiClient.post("/update-product-image", body);
}

export function updateProductImgAltContent(product, product_id) {
  const body = new FormData();
  body.append("alt_content", product.alt_content);
  body.append("product_id", product_id);
  return apiClient.post("/update-product-alt-content", body);
}

export function addProductVariant(variant, variantImg, productId) {
  const body = new FormData();
  body.append("product_id_fk", productId);
  body.append("p_v_name", variant.p_v_name);
  body.append("product_weight", variant.product_weight);
  body.append("product_price", variant.product_price);
  body.append("discount_percent", variant.discount_percent);
  body.append("final_price", variant.final_price);
  body.append("product_original_price", variant.product_original_price);
  body.append("total_quantity", variant.total_quantity);
  body.append("available_quantity", variant.available_quantity);
  body.append("color_id_fk", variant.color_id_fk);
  body.append("in_stock", variant.in_stock);
  body.append("rimg_alt_content", variant.rimg_alt_content);

  for (const file of variantImg) {
    body.append("image_path[]", file);
  }

  return apiClient.post("/store-varient", body);
}

export function updateProductVariant(variant) {
  const body = new FormData();
  body.append("product_id_fk", variant.product_id_fk);
  body.append("p_v_id", variant.p_v_id);
  body.append("p_v_name", variant.p_v_name);
  body.append("product_weight", variant.product_weight);
  body.append("product_price", variant.product_price);
  body.append("discount_percent", variant.discount_percent);
  body.append("product_original_price", variant.product_original_price);
  body.append("total_quantity", variant.total_quantity);
  body.append("available_quantity", variant.available_quantity);
  body.append("color_id_fk", variant.color_id_fk);
  body.append("in_stock", variant.in_stock);

  return apiClient.post("/update-varient", body);
}

export function addVariantImg(p_v_id, variantImg) {
  const body = new FormData();
  body.append("p_v_id", p_v_id);

  for (const file of variantImg) {
    body.append("image_path[]", file);
  }
  return apiClient.post(`/store-varient-images`, body);
}

export function addProductCategory(category, categoryImg) {
  const body = new FormData();
  body.append("keywords", category.keywords);
  body.append("meta_title", category.meta_title);
  body.append("meta_description", category.meta_description);
  body.append("p_category_name", category.p_category_name);
  body.append("pcat_title", category.pcat_title);
  body.append("pcat_subtitle", category.pcat_subtitle);
  body.append("pcat_short_desc", category.pcat_short_desc);
  body.append("pcat_long_desc", category.pcat_long_desc);
  body.append("pcat_image", categoryImg);
  return apiClient.post("/add-product-cate", body);
}

export function updateProductCategory(category) {
  const body = new FormData();
  body.append("keywords", category.keywords);
  body.append("meta_title", category.meta_title);
  body.append("meta_description", category.meta_description);
  body.append("p_category_name", category.p_category_name);
  body.append("pcat_title", category.pcat_title);
  body.append("pcat_subtitle", category.pcat_subtitle);
  body.append("pcat_short_desc", category.pcat_short_desc);
  body.append("pcat_long_desc", category.pcat_long_desc);
  body.append("p_category_id", category.p_category_id);
  return apiClient.post("/update-product-cate", body);
}

export function updateProductCategoryImg(p_category_id, categoryImg) {
  const body = new FormData();
  body.append("p_category_id", p_category_id);
  body.append("pcat_image", categoryImg);
  return apiClient.post("/update-product-cate-image", body);
}

export function addProductSubcategory(subcategory, subcategoryImg) {
  const body = new FormData();
  body.append("p_sub_category_name", subcategory.p_sub_category_name);
  body.append("p_category_id_fk", subcategory.p_category_id_fk);
  body.append("s_cat_title", subcategory.s_cat_title);
  body.append("s_cat_subtitle", subcategory.s_cat_subtitle);
  body.append("s_cat_short_desc", subcategory.s_cat_short_desc);
  body.append("s_cat_long_desc", subcategory.s_cat_long_desc);
  body.append("s_cat_image", subcategoryImg);
  return apiClient.post("/add-product-sub-cate", body);
}

export function updateProductSubcategory(subcategory) {
  const body = new FormData();
  body.append("p_sub_category_name", subcategory.p_sub_category_name);
  body.append("p_category_id_fk", subcategory.p_category_id_fk);
  body.append("s_cat_title", subcategory.s_cat_title);
  body.append("s_cat_subtitle", subcategory.s_cat_subtitle);
  body.append("s_cat_short_desc", subcategory.s_cat_short_desc);
  body.append("s_cat_long_desc", subcategory.s_cat_long_desc);
  body.append("s_cat_id", subcategory.s_cat_id);
  return apiClient.post("/update-product-sub-cate", body);
}

export function updateProductSubcategoryImg(s_cat_id, subcategoryImg) {
  const body = new FormData();
  body.append("s_cat_id", s_cat_id);
  body.append("s_cat_image", subcategoryImg);
  return apiClient.post("/update-product-sub-cate-img", body);
}

export function addSitemap(body) {
  return apiClient.post("/add-sitemap", body);
}

export function updateSitemap(body) {
  return apiClient.post("/update-sitemap", body);
}

export function addBannerSlider(bannerSlider, sliderImg) {
  const body = new FormData();
  body.append("bs_title", bannerSlider.bs_title);
  body.append("bs_subtitle", bannerSlider.bs_subtitle);
  body.append("bs_description", bannerSlider.bs_description);
  body.append("bs_img", sliderImg);
  return apiClient.post("/add-banner", body);
}

export function updateBannerSlider(bannerSlider, sliderImg) {
  const body = new FormData();
  body.append("bs_id", bannerSlider.bs_id);
  body.append("bs_title", bannerSlider.bs_title);
  body.append("bs_subtitle", bannerSlider.bs_subtitle);
  body.append("bs_description", bannerSlider.bs_description);
  body.append("bs_img", sliderImg);
  return apiClient.post("/update-banner", body);
}

export function addPromo(body) {
  return apiClient.post("/add-promo-code", body);
}

export function updatePromo(body) {
  return apiClient.post("/update-promo-code", body);
}

export function addCity(body) {
  return apiClient.post("/store-cities", body);
}

export function updateCity(city) {
  const body = new FormData();
  body.append("city_id", city.city_id);
  body.append("city_name", city.city_name);
  body.append("province_id_fk", city.province_id_fk);
  body.append("is_active", city.is_active);
  return apiClient.post("/update-cities", city);
}

export function addShippingCharge(body) {
  return apiClient.post("/add-shipping-charges", body);
}

export function UpdateShippingCharge(shippingCharges) {
  const body = new FormData();
  body.append("wc_id", shippingCharges.wc_id);
  body.append("city_id_fk", shippingCharges.city_id_fk);
  body.append("from_weight", shippingCharges.from_weight);
  body.append("to_weight", shippingCharges.to_weight);
  body.append("wc_charges", shippingCharges.wc_charges);
  return apiClient.post("/update-shipping-charges", shippingCharges);
}

export function orderStatus(order_id, statusId) {
  const body = new FormData();
  body.append("order_id", order_id);
  body.append("status_id_fk", statusId);

  return apiClient.post("/order-status", body);
}

export function addBlog(blog, blogImg) {
  const body = new FormData();
  body.append("keywords", blog.keywords);
  body.append("meta_title", blog.meta_title);
  body.append("meta_desc", blog.meta_desc);
  body.append("b_title", blog.b_title);
  body.append("b_short_desc", blog.b_short_desc);
  body.append("b_image", blogImg);
  body.append("b_long_desc", blog.b_long_desc);
  body.append("b_cid_fk", blog.b_cid_fk);
  return apiClient.post("/add-blog", body);
}

export function updateBlog(blog, blogImg) {
  const body = new FormData();
  body.append("keywords", blog.keywords);
  body.append("meta_title", blog.meta_title);
  body.append("meta_desc", blog.meta_desc);
  body.append("b_title", blog.b_title);
  body.append("b_short_desc", blog.b_short_desc);
  body.append("b_image", blogImg);
  body.append("b_long_desc", blog.b_long_desc);
  body.append("b_cid_fk", blog.b_cid_fk);
  body.append("b_id", blog.b_id);
  return apiClient.post("/update-blog", body);
}

export function addBlogCategory(body) {
  return apiClient.post("/add-blog-cat", body);
}

export function updateBlogCatrogry(body) {
  return apiClient.post("/update-blog-cat", body);
}

export function updatePromotion(promotion, promotionImg) {
  const body = new FormData();
  body.append("pi_id", promotion.pi_id);
  body.append("pi_title", promotion.pi_title);
  body.append("pi_subtitle", promotion.pi_subtitle);
  body.append("pi_description", promotion.pi_description);
  body.append("pi_img", promotionImg);
  return apiClient.post("/update-promotional-image", body);
}

export function addNotification(body) {
  return apiClient.post("/add-noti", body);
}

export function login(user) {
  return apiClient.post("/admin-login", user);
}
