import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import { Link } from "react-router-dom";

const Roles = () => {
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    apiClient
      .get("/get-user-role")
      .then((response) => setUsers(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  console.log(users);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <h3 className="card-title">Roles</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus text-white" />
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Created Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map((user) => (
                          <tr key={user.ur_id}>
                            <td className="project-actions">
                              <Link
                                className="btn btn-info btn-sm btn-custom"
                                to="/update-role"
                              >
                                <i className="fas fa-pencil-alt" /> View / Edit
                              </Link>
                            </td>
                            <td>Admin</td>
                            <td>2020-06-08 03 :06:26</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roles;
