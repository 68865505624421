import { Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllRouting from "./AllRouting";
import UserContext from "./contexts/UserContext";
import { useEffect, useState } from "react";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("userID");
    setUser(token);
  }, []);

  return (
    <div className="wrapper">
      <UserContext.Provider value={{ user, setUser }}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AllRouting />
      </UserContext.Provider>
    </div>
  );
}

export default App;
