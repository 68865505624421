import React, { useEffect, useState } from "react";
import apiClient from "../utils/api-client";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { addPromo } from "../services/userServices";
import { Link } from "react-router-dom";

const PromoCodes = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [errors, setErrors] = useState("");

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get("/get-promo-code")
      .then((response) => setPromoCodes(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const deactivatePromo = (pc_id) => {
    apiClient
      .post(`/update-promo-code-status?pc_id=${pc_id}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })
      .catch((error) => {
        toast.error("Error deactivating promo:", error);
      });
  };

  const onSubmit = (formData) => {
    addPromo(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding  banner slider.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3 ">
              <button
                type="button"
                className="btn btn-success btn-custom btn-sm"
                data-toggle="modal"
                data-target="#modal-default"
              >
                <i className="fas fa-plus" /> Add Promo Code
              </button>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 className="card-title"> Promo Codes</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped "
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Promo Code</th>
                          <th>Disocunted Value</th>
                          <th>Percentage</th>
                          <th>Order Value</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promoCodes.length ? (
                          promoCodes.map((item) => (
                            <tr key={item.pc_id}>
                              <td className="project-actions">
                                <Link
                                  to={`/update-promo-codes/${item.pc_id}`}
                                  className="btn btn-info btn-sm btn-custom"
                                >
                                  <i className="fas fa-pencil-alt" /> Edit
                                </Link>
                                {item.is_active > 0 ? (
                                  <button
                                    className="btn btn-danger btn-custom btn-sm m-1"
                                    onClick={() => deactivatePromo(item.pc_id)}
                                  >
                                    <i className="fas fa-trash"></i> Deavtive
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-custom btn-sm m-1"
                                    onClick={() => deactivatePromo(item.pc_id)}
                                  >
                                    <i className="fas fa-plus"></i> Activate
                                  </button>
                                )}
                              </td>
                              <td>{item.promo_code}</td>
                              <td>{item.discounted_value}</td>
                              <td>
                                {item.is_percentage > 0 ? (
                                  <span className="badge badge-success p-2">
                                    Percentage
                                  </span>
                                ) : (
                                  <span className="badge badge-warning p-2">
                                    Fixed
                                  </span>
                                )}
                              </td>
                              <td>{item.order_value}</td>
                              <td>
                                {item.is_active > 0 ? (
                                  <span className="badge badge-success p-2">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-danger p-2">
                                    Deactive
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          // Display message if no data available
                          <tr>
                            <td colSpan="6" className="text-center">
                              No Data Available In Table
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/*Add New Promo Modal */}
            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-header border-0 bg-info">
                      <h4 className="modal-title">Add New Promo Code</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>Enter Promo Code</label>
                        <input
                          type="text"
                          name="promo_code"
                          className="form-control"
                          id="promo_code"
                          required
                          {...register("promo_code")}
                        />
                      </div>
                      <div className="form-group">
                        <label>Discounted Value</label>
                        <input
                          type="text"
                          name="discounted_value"
                          className="form-control"
                          id="discounted_value"
                          required
                          {...register("discounted_value")}
                        />
                      </div>
                      <div className="form-group">
                        <label>Order Value</label>
                        <input
                          type="text"
                          name="order_value"
                          className="form-control"
                          id="order_value"
                          required
                          {...register("order_value")}
                        />
                      </div>
                      <div className="form-group">
                        <label>Percentage</label>
                        <select
                          className="form-control"
                          name="is_percentage"
                          required
                          {...register("is_percentage")}
                        >
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Update Promo Modal */}
            {/* <div className="modal fade" id="modal-default25">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form method="post" action="">
                    <div className="modal-header border-0 bg-info">
                      <h4 className="modal-title">Edit Promo Code</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>Enter Promo Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="promo_code"
                          name="promo_code"
                          defaultValue={12345}
                        />
                        <input
                          type="hidden"
                          name="pc_id"
                          id="pc_id"
                          defaultValue={5}
                        />
                      </div>
                      <div className="form-group">
                        <label>Discounted Value</label>
                        <input
                          type="text"
                          className="form-control"
                          id="discounted_value"
                          name="discounted_value"
                          defaultValue={20}
                        />
                      </div>
                      <div className="form-group">
                        <label>Order Value</label>
                        <input
                          type="text"
                          className="form-control"
                          id="order_value"
                          name="order_value"
                          defaultValue={2000}
                        />
                      </div>
                      <div className="form-group">
                        <label>Discounted Value</label>
                        <select
                          className="form-control"
                          name="is_percentage"
                          required
                        >
                          <option value={1} hidden>
                            Yes
                          </option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                          name="is_active"
                          id="is_active"
                          className="form-control"
                        >
                          <option value={1} hidden>
                            Active
                          </option>
                          <option value={0}>Not Active</option>
                          <option value={1}> Active</option>
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default PromoCodes;
