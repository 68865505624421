import React from "react";

const ReferralUsers = () => {
  const data = [
    {
      reffered_by: "Aamir Khan",
      reffered_to: "Asif",
      points: "10",
      status: "Active",
    },
  ];

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 className="card-title"> Referral Users</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped "
                    >
                      <thead>
                        <tr>
                          <th>Reffered By</th>
                          <th>Reffered To</th>
                          <th>Points</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.reffered_by}</td>
                            <td>{item.reffered_to}</td>
                            <td>{item.points}</td>
                            <td>
                              <span className="badge badge-success p-2">
                                {item.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralUsers;
