import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { toast } from "react-toastify";

const ProductPromotions = () => {
  const [promotions, setPromotions] = useState([]);
  const [errors, setErrors] = useState("");

  const [sliderImg, setSliderImg] = useState(null);

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get("/all-promotional-image")
      .then((response) => setPromotions(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const deactivatePromotion = (pi_id) => {
    apiClient
      .post(`/update-promotional-status?pi_id=${pi_id}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })
      .catch((error) => {
        toast.error("Error deactivating product promotion:", error);
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 className="card-title"> Permotional Images</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Image</th>
                          <th>Ttile</th>
                          <th>Subtitle</th>
                          <th>Description</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotions.map((item) => (
                          <tr key={item.pi_id}>
                            <td className="project-actions">
                              <Link
                                to={`/update-product-promo/${item.pi_id}`}
                                className="btn btn-info btn-sm btn-custom m-1"
                              >
                                <i className="fas fa-pencil-alt"></i> Edit
                              </Link>
                              {item.pi_status !== 0 ? (
                                <button
                                  className="btn btn-danger btn-custom btn-sm m-1"
                                  onClick={() =>
                                    deactivatePromotion(item.pi_id)
                                  }
                                >
                                  <i className="fas fa-trash"></i> Deavtive
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-custom btn-sm m-1"
                                  onClick={() =>
                                    deactivatePromotion(item.pi_id)
                                  }
                                >
                                  <i className="fas fa-plus"></i> Activate
                                </button>
                              )}
                            </td>
                            <td>
                              <img
                                src={`${IMAGE_BASE_URL}/${item && item.pi_img}`}
                                width={100}
                                className="img-fluid"
                              />
                            </td>
                            <td>{item.pi_title}</td>
                            <td>{item.pi_subtitle}</td>
                            <td>{item.pi_description}</td>
                            <td>
                              {item.pi_status !== 0 ? (
                                <span className="badge badge-success p-2">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-danger p-2">
                                  Deactive
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductPromotions;
