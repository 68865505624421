import React, { useEffect, useState } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader.jsx";
import SmallBox from "../../components/SmallBox/SmallBox.jsx";
import { Link } from "react-router-dom";
import apiClient from "../../utils/api-client.js";

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [cancelOrders, setCancelOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);

  const [errors, setErrors] = useState("");

  useEffect(() => {
    apiClient
      .get("/get-all-users")
      .then((response) => setUsers(response.data.data))
      .catch((err) => setErrors(err.message));

    apiClient
      .get("get-all-order")
      .then((response) => setOrders(response.data.data))
      .catch((err) => setErrors(err.message));

    apiClient
      .get("/get-all-product")
      .then((response) => setProducts(response.data.data))
      .catch((err) => setErrors(err.message));

    apiClient
      .get("/get-cancel-order")
      .then((response) => setCancelOrders(response.data.data))
      .catch((err) => setErrors(err.message));

    apiClient
      .get("/get-pending-order")
      .then((response) => setPendingOrders(response.data.data))
      .catch((err) => setErrors(err.message));

    apiClient
      .get("/get-process-order")
      .then((response) => setInProgressOrders(response.data.data))
      .catch((err) => setErrors(err.message));

    apiClient
      .get("/get-deliver-order")
      .then((response) => setDeliveredOrders(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  console.log("user:", users);
  console.log("pendingOrders:", pendingOrders);
  console.log("cancelOrders:", cancelOrders);
  console.log("deliveredOrders:", deliveredOrders);
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <SmallBox
                count={users && users.length}
                text="Total Users"
                icon="fa fa-users"
                link="/users"
                bgColor="bg-danger"
              />
            </div>
            <div className="col-lg-3 col-6">
              <SmallBox
                count={products && products.length}
                text="Total Products"
                icon="fas fa-th"
                link="/products"
                bgColor="bg-warning"
              />
            </div>
            <div className="col-lg-3 col-6">
              <SmallBox
                count={orders && orders.length}
                text="Total Orders"
                icon="fas fa-shopping-cart"
                link="/orders"
                bgColor="bg-success"
              />
            </div>
            <div className="col-lg-3 col-6">
              <SmallBox
                count="0"
                text="Today's Orders"
                icon="ion ion-bag"
                link="/orders"
                bgColor="bg-primary"
              />
            </div>
            <div className="col-lg-3 col-6">
              <SmallBox
                count={pendingOrders && pendingOrders.length}
                text="Pending Orders"
                icon="fas fa-cart-arrow-down"
                link="/orders"
                bgColor="bg-teal"
              />
            </div>
            <div className="col-lg-3 col-6">
              <SmallBox
                count={cancelOrders && cancelOrders.length}
                text="Cancel Orders"
                icon="fas fa-cart-arrow-down"
                link="/orders"
                bgColor="bg-info"
              />
            </div>
            <div className="col-lg-3 col-6">
              <SmallBox
                count={deliveredOrders && deliveredOrders.length}
                text="Delivered Orders"
                icon="fas fa-cart-arrow-down"
                link="/orders"
                bgColor="bg-maroon"
              />
            </div>

            <div className="col-lg-3 col-6">
              <SmallBox
                count={inProgressOrders && setInProgressOrders.length}
                text="In Progress Orders"
                icon="fas fa-cart-arrow-down"
                link="/orders"
                bgColor="bg-purple"
              />
            </div>
          </div>

          {/* Tables Section */}
          <div className="row">
            <div className="col-md-6">
              <div className="card card-info">
                <div className="card-header bg-danger">
                  <h3 className="card-title">Recent Users</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Mapping over filtered data */}
                        {users.slice(0, 5).map((user) => (
                          <tr key={user.user_id}>
                            <td>{user.full_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.address}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer clearfix">
                  <Link
                    to="/users"
                    className="btn btn-sm btn-danger float-right btn-custom"
                  >
                    <i className="fas fa-eye" /> View All Orders
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card card-info">
                <div className="card-header bg-info">
                  <h3 className="card-title">Recent Products</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Category</th>
                          <th>Sub Category</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.slice(0, 4).map((product) => (
                          <tr key={product.product_id} className="text-middle">
                            <td>{product.product_name}</td>
                            <td>{product.category.p_category_name}</td>
                            <td>{product.subcategory.p_sub_category_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer clearfix">
                  <Link
                    to="/products"
                    className="btn btn-sm btn-info float-right btn-custom"
                  >
                    <i className="fas fa-eye" /> View All Products
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* // */}
        </div>
      </section>
    </>
  );
};

export default Dashboard;
