import React from "react";
import Header from "./components/Header/Header";
import MenuSidebar from "./components/MenuSidebar/MenuSidebar";
import Footer from "./components/Footer/Footer";
import { Outlet } from "react-router-dom";
import "./Main.css"; // Import your CSS file for styling

const Main = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="sidebar-wrapper">
        <MenuSidebar />
      </div>
      <div className="content-wrapper">
        <div className="content pt-3">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
