import React, { useEffect, useState } from "react";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { useParams } from "react-router-dom";

const ViewBLog = () => {
  const { b_id } = useParams();
  const [blogDetails, setBlogDetails] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    apiClient
      .get(`get-single-blog/${b_id}`)
      .then((response) => setBlogDetails(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Article Title</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <p className="p-3">{blogDetails.b_title}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Article Detail</h3>
                </div>
                <img
                  className="card-img-top"
                  src={`${IMAGE_BASE_URL}/${
                    blogDetails && blogDetails.b_image
                  }`}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <p className="card-text">
                    Published Date:{" "}
                    <span className="badge badge-primary">
                      {blogDetails.b_datetime}
                    </span>
                  </p>
                  <p className="card-text">
                    Article Status:
                    <span className="badge ">Active</span>
                    {blogDetails.is_active > 0 ? (
                      <span className="badge badge-primary">Active</span>
                    ) : (
                      <span className="badge badge-danger">Deactive</span>
                    )}
                  </p>
                  <p className="card-text">
                    Categories:
                    <span className="badge badge-primary">
                      {blogDetails.blog_cate && blogDetails.blog_cate.bcat_name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Article Description</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tab-content p-3">
                    <div className="tab-pane active" id="profile">
                      <div className="row">
                        <div
                          className="col-md-12 MsoNormal"
                          dangerouslySetInnerHTML={{
                            __html: blogDetails.b_long_desc,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewBLog;
