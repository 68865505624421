import React, { useEffect, useState } from "react";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ProductsVariants from "./ProductsVariants";
import {
  updateProductDetails,
  updateProductImg,
  updateProductImgAltContent,
} from "../../services/userServices";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const UpdateProduct = () => {
  const { product_id } = useParams();
  const [producDetails, setPoducDetails] = useState([]);
  const [productCategories, setPoductCategories] = useState([]);
  const [productSubcategories, setProductSubcategories] = useState([]);
  const [errors, setErrors] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const [isYoutubeVideo, setIsYoutubeVideo] = useState(0);
  const [isFeatured, setIsFeatured] = useState(0);
  const [inStock, setInStock] = useState(1);

  const [productImg, setProductImg] = useState(null);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      keywords: producDetails.keywords || "",
      meta_title: producDetails.meta_title || "",
      meta_desc: producDetails.meta_desc || "",
      product_name: producDetails.product_name || "",
      product_id: producDetails.product_id || "",
      product_details: producDetails.product_details || "",
      in_stock: producDetails.in_stock || "",
      is_featured: (producDetails && producDetails.is_featured) || "",
      category_id_fk: producDetails.category_id_fk || "",
      subcat_id_fk: producDetails.subcat_id_fk || "",
      is_youtube_video: (producDetails && producDetails.is_youtube_video) || "",
      youtube_video_url: producDetails.youtube_video_url || "0",
      youtube_video_id: producDetails.youtube_video_id || "0",
    },
  });

  // Update the form values if they change
  useEffect(() => {
    setValue("keywords", producDetails.keywords || "");
    setValue("meta_title", producDetails.meta_title || "");
    setValue("meta_desc", producDetails.meta_desc || "");
    setValue("product_name", producDetails.product_name || "");
    setValue("product_id", producDetails.product_id || "");
    setValue("product_details", producDetails.product_details || "");
    setValue("in_stock", producDetails.in_stock || 0);
    setValue("is_featured", (producDetails && producDetails.is_featured) || 0);
    setValue("category_id_fk", producDetails.category_id_fk || "");
    setValue("subcat_id_fk", producDetails.subcat_id_fk || "");
    setValue(
      "is_youtube_video",
      (producDetails && producDetails.is_youtube_video) || 0
    );
    setValue("youtube_video_url", producDetails.youtube_video_url || "");
    setValue("youtube_video_id", producDetails.youtube_video_id || "");
  }, [producDetails]); // Triggered when producDetails changes

  useEffect(() => {
    if (producDetails && producDetails.is_youtube_video !== undefined) {
      setIsYoutubeVideo(producDetails.is_youtube_video ? 1 : 0);
    }

    if (producDetails && producDetails.is_featured !== undefined) {
      setIsFeatured(producDetails.is_featured ? 1 : 0);
    }

    if (producDetails && producDetails.in_stock !== undefined) {
      setInStock(producDetails.in_stock ? 1 : 0);
    }
  }, [producDetails]);

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get(`/get-product/${product_id}`)
      .then((response) => {
        setPoducDetails(response.data.data);
        setSelectedCategoryId(response.data.data.category.p_category_id);
      })
      .catch((err) => setErrors(err.message));
  };

  useEffect(() => {
    apiClient
      .get("/get-product-cate")
      .then((response) => setPoductCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      apiClient
        .get(`/subcate-by-category/${selectedCategoryId}`)
        .then((response) => setProductSubcategories(response.data.data))
        .catch((err) => setErrors(err.message));
    }
  }, [selectedCategoryId]);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategoryId(categoryId);
  };

  const handleYoutubeVideoChange = (event) => {
    setIsYoutubeVideo(event.target.value);
  };

  const handleFeaturedChange = (event) => {
    setIsFeatured(event.target.value);
  };

  const handleStockChange = (event) => {
    setInStock(event.target.value);
  };

  const handleUpdateProduct = (formData) => {
    updateProductDetails(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);

          navigate("/products");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating product details.");
      });
  };

  const handleImgAltContent = (formData) => {
    updateProductImgAltContent(formData, product_id)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating alt content");
      });
  };

  const handleImgChange = () => {
    updateProductImg(product_id, productImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating product image.");
      });
  };

  const deleteProductImg = (productId) => {
    apiClient
      .post(`/delete-product-image?product_id=${productId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })
      .catch((error) => {
        toast.error("Error deleting product image:", error);
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Product Image</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(handleImgAltContent)}>
                    <div className="row">
                      <div className="col-lg-2 mb-4">
                        <label>Main Image</label>
                        <div
                          className="pt-2 old_img"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={`${IMAGE_BASE_URL}/${producDetails && producDetails.product_image
                              }`}
                            className="img-fluid"
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <a
                              className="text-left text-secondary"
                              onClick={() =>
                                deleteProductImg(producDetails.product_id)
                              }
                            >
                              <i
                                className="fa fa-times fa-3x"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group">
                          <label>Alt Content</label>
                          <textarea
                            name="alt_content"
                            className="form-control"
                            rows={6}
                            defaultValue={
                              producDetails && producDetails.alt_content
                            }
                            {...register("alt_content")}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="float-right">
                          <button
                            type="submit"
                            className="btn btn-info btn-custom"
                          >
                            <i className="fas fa-plus" /> Update content
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <form onSubmit={handleSubmit(handleImgChange)}>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <input
                          className="form-control"
                          type="file"
                          id="upload_main_image"
                          name="upload_main_image"
                          onChange={(e) => setProductImg(e.target.files[0])}
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="float-right">
                          <button
                            type="submit"
                            className="btn btn-info btn-custom"
                          >
                            <i className="fas fa-plus" /> Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <form onSubmit={handleSubmit(handleUpdateProduct)}>
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Product Detail</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Keywords</label>
                              <input
                                type="text"
                                name="keywords"
                                className="form-control"
                                id="keywords"
                                defaultValue={
                                  producDetails && producDetails.keywords
                                }
                                required
                                {...register("keywords")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Meta Title</label>
                              <input
                                type="text"
                                name="meta_title"
                                className="form-control"
                                id="meta_title"
                                defaultValue={
                                  producDetails && producDetails.meta_title
                                }
                                required
                                {...register("meta_title")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Meta Description</label>
                              <input
                                type="text"
                                name="meta_desc"
                                className="form-control"
                                id="meta_desc"
                                defaultValue={
                                  producDetails && producDetails.meta_desc
                                }
                                required
                                {...register("meta_desc")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Product Title</label>
                              <input
                                type="text"
                                name="product_name"
                                className="form-control"
                                id="product_name"
                                defaultValue={
                                  producDetails && producDetails.product_name
                                }
                                required
                                {...register("product_name")}
                              />
                              <input
                                type="hidden"
                                name="p_id"
                                value={
                                  producDetails && producDetails.product_id
                                }
                                {...register("product_id")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="input-10">Product Description</label>
                          <textarea
                            name="product_details"
                            className="form-control"
                            id="product_details"
                            rows={15}
                            requried
                            defaultValue={
                              producDetails && producDetails.product_details
                            }
                            {...register("product_details")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">Select Category</label>
                          <select
                            id="inputStatus"
                            className="form-control category_id_fk"
                            name="category_id_fk"
                            required
                            {...register("category_id_fk")}
                            onChange={handleCategoryChange}
                          >
                            <option
                              value={
                                producDetails &&
                                producDetails.category &&
                                producDetails.category.p_category_id
                              }
                              hidden
                            >
                              {producDetails &&
                                producDetails.category &&
                                producDetails.category.p_category_name}
                            </option>
                            {productCategories &&
                              productCategories.map((productCategory) => (
                                <option value={productCategory.p_category_id}>
                                  {productCategory.p_category_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-10">Sub Category</label>
                          <select
                            id="inputStatus"
                            className="form-control subcat_id_fk"
                            name="subcat_id_fk"
                            required
                            {...register("subcat_id_fk")}
                          >
                            <option
                              value={
                                producDetails &&
                                producDetails.category &&
                                producDetails.category.s_cat_id
                              }
                              hidden
                            >
                              {producDetails &&
                                producDetails.subcategory &&
                                producDetails.subcategory.p_sub_category_name}
                            </option>
                            {productSubcategories &&
                              productSubcategories.map((productSubcategory) => (
                                <option value={productSubcategory.s_cat_id}>
                                  {productSubcategory.p_sub_category_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">Youtube Video</label>
                          <select
                            id="inputStatus"
                            className="form-control custom-select"
                            name="is_youtube_video"
                            required
                            {...register("is_youtube_video")}
                            onChange={handleYoutubeVideoChange}
                            value={isYoutubeVideo}
                          >
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-10">Youtube Video Url </label>
                          <input
                            type="text"
                            id="video_url"
                            name="video_url"
                            className="form-control"
                            defaultValue={
                              producDetails && producDetails.youtube_video_url
                            }
                            {...register("youtube_video_url")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-10">Youtube Video ID </label>
                          <input
                            type="text"
                            id="youtube_video_id"
                            name="youtube_video_id"
                            className="form-control"
                            defaultValue={
                              producDetails && producDetails.youtube_video_id
                            }
                            {...register("youtube_video_id")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">In Stock</label>
                          <select
                            id="in_stock"
                            className="form-control"
                            name="in_stock"
                            required
                            {...register("in_stock")}
                            onChange={handleStockChange}
                            value={inStock}
                          >
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">Featured</label>
                          <select
                            id="is_featured"
                            className="form-control"
                            name="is_featured"
                            required
                            {...register("is_featured")}
                            onChange={handleFeaturedChange}
                            value={isFeatured}
                          >
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-plus" /> Update Product
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* varients */}
            <ProductsVariants productId={producDetails.product_id} />
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProduct;
