import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../services/userServices";
import { useForm } from "react-hook-form";
import UserContext from "../../contexts/UserContext";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const [passwordType, setPasswordType] = useState("password");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (formData, e) => {
    login(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          localStorage.setItem("userID", response.data.data);
          localStorage.setItem("userEmail", response.data.email);
          toast.success("Login successful");
          setUser(response.data.data);

          const { state } = location;
          navigate(state ? state.from : "/");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        // Handle network errors or other unexpected errors
        toast.error("An error occurred while login.");
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  return (
    <>
      <div style={{ backgroundColor: "#e9ecef" }}>
        <div className="container pt-3">
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
        <div className="hold-transition login-page">
          <div className="login-box">
            <div className="login-logo"></div>
            <div className="card card-outline card-info">
              <div className="card-header text-center">
                <h1 className="h1">
                  <b>BAKUMIA</b>
                </h1>
                <h3>
                  <b>Admin Portal</b>
                </h3>
              </div>
              <div className="card-body">
                <p className="login-box-msg">Sign in to your account</p>
                <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <em className="form_error">Please enter your email.</em>
                    )}
                    {errors.email?.type === "maxLength" && (
                      <em className="form_error">
                        Email should be less than 50 characters.
                      </em>
                    )}
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type={passwordType}
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      {...register("password", {
                        required: true,
                      })}
                    />

                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <p className="text-right ml-auto">
                    {/* <button
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      className=" btn  text-center"
                    >
                      Forgot Password
                    </button> */}
                  </p>
                  <div className="row">
                    <div className="col-8">
                      <div className="icheck-primary">
                        <input
                          type="checkbox"
                          id="remember"
                          onClick={togglePasswordVisibility}
                        />
                        <label htmlFor="remember">Show Password</label>
                      </div>
                    </div>
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-info btn-custom btn-block"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Modal
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Reset Your Password
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="password_forgot" method="post">
                  <div className="col-md-12 form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter Your Email"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-info btn-custom btn-block"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Login;
