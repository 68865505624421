import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { toast } from "react-toastify";
import { addBannerSlider } from "../../services/userServices";

const BannerSlider = () => {
  const [bannerSliders, setBannerSliders] = useState([]);
  const [errors, setErrors] = useState("");
  const { register, handleSubmit } = useForm();

  const [sliderImg, setSliderImg] = useState(null);

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get("/get-banner-slider")
      .then((response) => setBannerSliders(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const onSubmit = (formData) => {
    addBannerSlider(formData, sliderImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding  banner slider.");
      });
  };

  const deactivateSlider = (bs_id) => {
    apiClient
      .post(`/update-banner-slider-status?bs_id=${bs_id}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })
      .catch((error) => {
        toast.error("Error deactivating slider:", error);
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3">
              <button
                type="button"
                className="btn btn-success btn-custom btn-sm"
                data-toggle="modal"
                data-target="#modal-default"
              >
                <i className="fas fa-plus" /> Add New Slider
              </button>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 className="card-title"> Slider Images</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Image</th>
                          <th>Ttile</th>
                          <th>Subtitle</th>
                          <th>Description</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bannerSliders &&
                          bannerSliders.map((item) => (
                            <tr key={item.bs_id}>
                              <td className="project-actions">
                                <Link
                                  to={`/update-slider/${item.bs_id}`}
                                  className="btn btn-info btn-sm btn-custom m-1"
                                >
                                  <i className="fas fa-pencil-alt"></i> Edit
                                </Link>
                                {item.is_active > 0 ? (
                                  <button
                                    className="btn btn-danger btn-custom btn-sm m-1"
                                    onClick={() => deactivateSlider(item.bs_id)}
                                  >
                                    <i className="fas fa-trash"></i> Deavtive
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-custom btn-sm m-1"
                                    onClick={() => deactivateSlider(item.bs_id)}
                                  >
                                    <i className="fas fa-plus"></i> Activate
                                  </button>
                                )}
                              </td>
                              <td>
                                <img
                                  src={`${IMAGE_BASE_URL}/${
                                    item && item.bs_img
                                  }`}
                                  width={100}
                                  className="img-fluid"
                                />
                              </td>
                              <td>{item.bs_title}</td>
                              <td>{item.bs_subtitle}</td>
                              <td>{item.bs_description}</td>
                              <td>
                                {item.is_active > 0 ? (
                                  <span className="badge badge-success p-2">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-danger p-2">
                                    Deactive
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="modal-content">
                    <div className="modal-header border-0 bg-info">
                      <h4 className="modal-title">Add Slider Details</h4>
                      <button
                        type="button"
                        className="close text-white"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Enter Title</label>
                          <input
                            type="text"
                            name="bs_title"
                            className="form-control"
                            id="bs_title"
                            maxLength={25}
                            required
                            {...register("bs_title")}
                          />
                        </div>
                        <div className="form-group">
                          <label>Enter Sub title</label>
                          <input
                            type="text"
                            name="bs_subtitle"
                            className="form-control"
                            id="bs_subtitle"
                            maxLength={25}
                            required
                            {...register("bs_subtitle")}
                          />
                        </div>
                        <div className="form-group">
                          <label>Enter Description</label>
                          <input
                            type="text"
                            name="bs_description"
                            className="form-control"
                            id="bs_description"
                            maxLength={30}
                            required
                            {...register("bs_description")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-4">
                        <label>Product Image</label>
                        <input
                          className="form-control"
                          type="file"
                          id="upload_image1"
                          name="upload_image1"
                          onChange={(e) => setSliderImg(e.target.files[0])}
                        />
                        <div id="uploaded_image1" className="ml-auto pt-2" />
                        <div
                          className="modal"
                          id="image_modal1"
                          data-backdrop="static"
                          data-keyboard="false"
                          tabIndex={-1}
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="col-lg-12">
                                  <div id="image_demo1" />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary crop_image1"
                                >
                                  Save and upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        Save changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerSlider;
