import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "./../../utils/api-client";

const PushNotifications = () => {
  const [notification, setNotificationProducts] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    apiClient
      .get(`/get-noti`)
      .then((res) => setNotificationProducts(res.data.data))
      .catch((err) => setError(err.message));
  }, []);

  console.log("Noti", notification);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3">
              <Link
                to="/add-notification"
                className="btn btn-custom btn-success"
              >
                <i className="fas fa-plus" /> Add new notification
              </Link>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-lg-9 col-sm-6">
                      <h3 className="card-title">All push notifications</h3>
                    </div>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>User Type</th>
                        <th>Notification Type</th>
                        <th>Title</th>
                        <th>Details</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notification.map((item) => (
                        <tr key={item.noti_id}>
                          <td>{item.usertype && item.usertype.ut_name}</td>
                          <td>
                            {item.noticategory &&
                              item.noticategory.category_name}
                          </td>
                          <td>{item.noti_title}</td>
                          <td>{item.noti_desc}</td>
                          <td>{item.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PushNotifications;
