import React from "react";

const UpdateProductPromotions = () => {
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form
                id="quickForm"
                method="post"
                action=""
                encType="multipart/form-data"
              >
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Edit Promotions</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="pi_title"
                            className="form-control"
                            id="pi_title"
                            maxLength={25}
                            defaultValue="Kitchen Products"
                            required
                          />
                          <input
                            type="hidden"
                            name="pi_id"
                            className="form-control"
                            id="pi_id"
                            defaultValue={1}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Subtitle</label>
                          <input
                            type="text"
                            name="pi_subtitle"
                            className="form-control"
                            id="pi_subtitle"
                            maxLength={50}
                            defaultValue="All can be yours"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <input
                            type="text"
                            name="pi_description"
                            className="form-control"
                            id="pi_description"
                            maxLength={50}
                            defaultValue="You Kitchen Partner"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12 border">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Promotion Image</label>
                              <input
                                className="form-control"
                                type="file"
                                id="upload_image1"
                                name="upload_image1"
                              />
                              <div
                                id="uploaded_image1"
                                className="ml-auto pt-2"
                              />
                              <div className="col-md-12">
                                <div id="previous_image" className="my-3">
                                  <img
                                    src="https://admin-bakumia.devifysolutions.pk/media/promotional_images/1693569534.webp"
                                    height={100}
                                    className="img-rounded"
                                  />
                                </div>
                              </div>
                              <div
                                className="modal"
                                id="image_modal1"
                                data-backdrop="static"
                                data-keyboard="false"
                                tabIndex={-1}
                                role="dialog"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-body">
                                      <div className="col-lg-12">
                                        <div id="image_demo1" />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-primary crop_image1"
                                      >
                                        Save and upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProductPromotions;
