import React from "react";

// Pages
import Main from "./Main";
import Dashboard from "./pages/Dashboard/Dashboard";
import Roles from "./pages/RolesManagement/Roles";
import AssignRoles from "./pages/RolesManagement/AssignRoles";
import Sitemap from "./pages/Sitemap";
import Users from "./pages/Users/Users";
import AllProducts from "./pages/Products/AllProducts";
import ProductsCategory from "./pages/Products/ProductsCategory";
import ProductsSubCategories from "./pages/Products/ProductsSubCategories";
import BannerSlider from "./pages/Products/BannerSlider";
import ProductPromotions from "./pages/Products/ProductPromotions";
import AllBlogs from "./pages/Blogs/AllBlogs";
import AddNewBlog from "./pages/Blogs/AddNewBlog";
import BlogCategories from "./pages/Blogs/BlogCategories";
import PushNotifications from "./pages/Notifications/PushNotifications";
import AllOrders from "./pages/Orders/AllOrders";
import Cities from "./pages/Orders/Cities";
import ShippingCharges from "./pages/Orders/ShippingCharges";
import Reviews from "./pages/Orders/Reviews";
import PromoCodes from "./pages/PromoCodes";
import ReferralUsers from "./pages/ReferralUsers";
import Logout from "./pages/Logout";
import UpdateProduct from "./pages/Products/UpdateProduct";
import UpdateProductCategory from "./pages/Products/UpdateProductCategory";
import UpdateProductSubcategory from "./pages/Products/UpdateProductSubcategory";
import UpdateBannerSlider from "./pages/Products/UpdateBannerSlider";
import UpdateProductPromotions from "./pages/Products/UpdateProductPromotions";
import UpdateRole from "./pages/RolesManagement/UpdateRole";
import UpdateSitemap from "./pages/UpdateSitemap";
import AddPushNotification from "./pages/Notifications/AddPushNotification";
import OrderDetails from "./pages/Orders/OrderDetails";
import EditBlog from "./pages/Blogs/EditBlog";
import ViewBlog from "./pages/Blogs/ViewBlog";
import Login from "./pages/Login/Login";
import AddProductCategory from "./pages/Products/AddProductCategory";
import UpdateProductVariant from "./pages/Products/UpdateProductVariant";
import AddProduct from "./pages/Products/AddProduct";
import AddProductSubcategory from "./pages/Products/AddProductSubcategory";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UpdateCity from "./pages/Orders/UpdateCity";
import UpdateShippingCharges from "./pages/Orders/UpdateShippingCharges";
import UpdatePromoCode from "./pages/UpdatePromoCode";
import UpdatePromotion from "./pages/Products/UpdatePromotion";
import OrderInvoice from "./pages/Orders/OrderInvoice";
import NotFoundPage from "./pages/NotFound/NotFoundPage";

const AllRouting = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="sitemap" element={<Sitemap />} />
          <Route path="update-sitemap/:s_id" element={<UpdateSitemap />} />
          <Route path="roles" element={<Roles />} />
          <Route path="update-role" element={<UpdateRole />} />
          <Route path="assign-roles" element={<AssignRoles />} />
          <Route path="users" element={<Users />} />
          <Route path="products" element={<AllProducts />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route
            path="update-product/:product_id"
            element={<UpdateProduct />}
          />
          <Route
            path="update-variant/:p_v_id"
            element={<UpdateProductVariant />}
          />
          <Route path="products-category" element={<ProductsCategory />} />
          <Route path="add-product-category" element={<AddProductCategory />} />
          <Route
            path="update-product-cat/:p_category_id"
            element={<UpdateProductCategory />}
          />

          <Route path="subcategories" element={<ProductsSubCategories />} />
          <Route
            path="add-product-subcategory"
            element={<AddProductSubcategory />}
          />
          <Route
            path="update-product-subcat/:s_cat_id"
            element={<UpdateProductSubcategory />}
          />
          <Route path="banner-slider" element={<BannerSlider />} />
          <Route path="update-slider/:bs_id" element={<UpdateBannerSlider />} />
          <Route path="product-promotions" element={<ProductPromotions />} />
          <Route
            path="update-product-promo/:"
            element={<UpdateProductPromotions />}
          />
          <Route path="blogs" element={<AllBlogs />} />
          <Route path="view-blog/:b_id" element={<ViewBlog />} />
          <Route path="edit-blog/:b_id" element={<EditBlog />} />
          <Route path="new-blog" element={<AddNewBlog />} />
          <Route path="blog-categories" element={<BlogCategories />} />
          <Route path="push-notifications" element={<PushNotifications />} />
          <Route path="add-notification" element={<AddPushNotification />} />
          <Route path="orders" element={<AllOrders />} />
          <Route path="order-details/:order_id" element={<OrderDetails />} />
          <Route path="order-invoice/:order_id" element={<OrderInvoice />} />
          <Route path="cities" element={<Cities />} />
          <Route path="update-city/:city_id" element={<UpdateCity />} />
          <Route path="shipping-charges" element={<ShippingCharges />} />
          <Route
            path="update-shipping-charges/:wc_id"
            element={<UpdateShippingCharges />}
          />
          <Route path="reviews" element={<Reviews />} />
          <Route path="promo-codes" element={<PromoCodes />} />
          <Route
            path="update-product-promo/:pi_id"
            element={<UpdatePromotion />}
          />
          <Route
            path="update-promo-codes/:pc_id"
            element={<UpdatePromoCode />}
          />
          <Route path="referral-users" element={<ReferralUsers />} />

          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default AllRouting;
