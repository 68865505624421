import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateBannerSlider } from "../../services/userServices";

const UpdateBannerSlider = () => {
  const { bs_id } = useParams();
  const [slider, setSlider] = useState([]);
  const [errors, setErrors] = useState([]);

  const [sliderImg, setSliderImg] = useState(null);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      bs_title: slider.bs_title || "",
      bs_subtitle: slider.bs_subtitle || "",
      bs_description: slider.bs_description || "",
      pi_id: slider.pi_id || "",
    },
  });

  useEffect(() => {
    setValue("bs_title", slider.bs_title || "");
    setValue("bs_subtitle", slider.bs_subtitle || "");
    setValue("bs_description", slider.bs_description || "");
    setValue("pi_id", slider.pi_id || "");
  }, [slider]);

  useEffect(() => {
    apiClient
      .get(`/get-single-banner-slider/${bs_id}`)
      .then((response) => setSlider(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    updateBannerSlider(formData, sliderImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/banner-slider");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating banner slider.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Edit Slider</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="bs_title"
                            className="form-control"
                            id="bs_title"
                            maxLength={25}
                            defaultValue={slider.bs_title}
                            required
                            {...register("bs_title")}
                          />
                          <input
                            type="hidden"
                            name="bs_id"
                            className="form-control"
                            id="bs_id"
                            defaultValue={slider.bs_id}
                            required
                            {...register("bs_id")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Subtitle</label>
                          <input
                            type="text"
                            name="bs_subtitle"
                            className="form-control"
                            id="bs_subtitle"
                            maxLength={25}
                            defaultValue={slider.bs_subtitle}
                            required
                            {...register("bs_subtitle")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <input
                            type="text"
                            name="bs_description"
                            className="form-control"
                            id="bs_description"
                            maxLength={25}
                            defaultValue={slider.bs_description}
                            required
                            {...register("bs_description")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12 border">
                          <label>Product Image</label>
                          <input
                            className="form-control"
                            type="file"
                            id="upload_image1"
                            name="upload_image1"
                            onChange={(e) => setSliderImg(e.target.files[0])}
                          />
                          <div id="uploaded_image1" className="ml-auto pt-2" />
                          <div className="col-md-12">
                            <div id="previous_image" className="my-3">
                              <img
                                src={`${IMAGE_BASE_URL}/${slider && slider.bs_img
                                  }`}
                                height={100}
                                className="img-rounded"
                              />
                            </div>
                          </div>
                          <div
                            className="modal"
                            id="image_modal1"
                            data-backdrop="static"
                            data-keyboard="false"
                            tabIndex={-1}
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="col-lg-12">
                                    <div id="image_demo1" />
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-primary crop_image1"
                                  >
                                    Save and upload
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateBannerSlider;
