import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addCity, updateCity } from "../../services/userServices";
import { NavLink } from "react-router-dom";

const Cities = () => {
  const [cities, setCities] = useState([]);
  const [province, setProvince] = useState([]);
  const [errors, setErrors] = useState("");

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get("/get-cities")
      .then((response) => setCities(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  useEffect(() => {
    apiClient
      .get("/get-province")
      .then((response) => setProvince(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    addCity(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding  city.");
      });
  };

  // const handleUpdateCity = (formData) => {
  //   updateCity(formData)
  //     .then((response) => {
  //       if (response && response.data.status === true) {
  //         toast.success(response.data.message);
  //         fetchProductsList();
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("An error occurred while adding  city.");
  //     });
  // };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-right mb-3">
              <button
                type="button"
                className="btn btn-info btn-custom btn-sm"
                data-toggle="modal"
                data-target="#modal-default"
              >
                <i className="fas fa-plus" /> Add city
              </button>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {cities &&
                    cities.map((city) => (
                      // <form onSubmit={handleSubmit(handleUpdateCity)}>
                      <div className="row" key={city.city_id}>
                        <div className="col-md-3">
                          <label>Choose Province</label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="province_id_fk"
                              required
                              disabled
                              // {...register("province_id_fk")}
                              defaultValue={city.province_id_fk}
                            >
                              {province &&
                                province.map((item) => (
                                  <option value={item.province_id}>
                                    {item.province_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label>City Name</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="city_name"
                              className="form-control"
                              defaultValue={city.city_name}
                              required
                              disabled
                              // {...register("city_name")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label>Status</label>
                          <select
                            className="form-control"
                            name="is_active"
                            required
                            {...register("is_active")}
                            defaultValue={city && city.is_active}
                            disabled
                          >
                            <option value={0}>Not Active</option>
                            <option value={1}>Active</option>
                          </select>
                        </div>
                        <div className="col-md-2">
                          <label>Action</label>
                          <div className="form-group">
                            <NavLink
                              to={`/update-city/${city.city_id}`}
                              className="btn btn-info"
                            >
                              <i className="fas fa-pencil-alt" /> Edit
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      // </form>
                    ))}
                </div>
              </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-header bg-info border-0">
                      <h4 className="modal-title">Add city detail</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Choose Province</label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="province_id_fk"
                              required
                              {...register("province_id_fk")}
                            >
                              {province &&
                                province.map((item) => (
                                  <option value={item.province_id}>
                                    {item.province_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>City</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="city_name"
                              className="form-control"
                              id="city_name"
                              required
                              {...register("city_name")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cities;
