import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";

const ProductsCategory = () => {
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    apiClient
      .get("/get-product-cate")
      .then((response) => setCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const deactivateCategory = (categoryId) => {
    apiClient
      .post(`/update-product-cate-status?p_category_id=${categoryId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchCategories();
      })
      .catch((error) => {
        toast.error("Error try again!", error);
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3">
              <Link
                to="/add-product-category"
                className="btn btn-success btn-custom btn-sm"
              >
                <i className="fas fa-plus" /> Add New Category
              </Link>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-md-9 d-flex align-items-center">
                      <h3 className="card-title m-1"> Product Categories</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Category Name</th>
                          <th>Status</th>
                          <th>Date / Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Mapping over data */}
                        {categories &&
                          categories.map((category) => (
                            <tr key={category.p_category_id}>
                              <td className="project-actions">
                                <Link
                                  to={`/update-product-cat/${category.p_category_id}`}
                                  className="btn btn-info btn-sm btn-custom m-1"
                                  // href={item.action.edit}
                                >
                                  <i className="fas fa-pencil-alt"></i> Edit
                                </Link>
                                {category.is_deleted < 1 ? (
                                  <button
                                    className="btn btn-danger btn-custom btn-sm m-1"
                                    onClick={() =>
                                      deactivateCategory(category.p_category_id)
                                    }
                                  >
                                    <i className="fas fa-trash"></i> Deavtive
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-custom btn-sm m-1"
                                    onClick={() =>
                                      deactivateCategory(category.p_category_id)
                                    }
                                  >
                                    <i className="fas fa-plus"></i> Activate
                                  </button>
                                )}
                              </td>
                              <td>{category.p_category_name}</td>
                              <td>
                                {category.is_deleted != 1 && (
                                  <span className="badge badge-success p-2">
                                    Active
                                  </span>
                                )}
                                {category.is_deleted != 0 && (
                                  <span className="badge badge-danger p-2">
                                    Deactive
                                  </span>
                                )}
                              </td>
                              <td>{category.timestamp}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsCategory;
