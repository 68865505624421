import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { addProductCategory } from "../../services/userServices";
import { toast } from "react-toastify";

const AddProductCategory = () => {
  const [categoryImg, setCategoryImg] = useState(null);

  const { register, handleSubmit } = useForm();

  const onSubmit = (formData) => {
    addProductCategory(formData, categoryImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding product category.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Add Category</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="name">Keywords</label>
                          <input
                            type="text"
                            name="keywords"
                            className="form-control"
                            id="keywords"
                            required
                            {...register("keywords")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category-title">Meta Title</label>
                          <input
                            type="text"
                            id="meta_title"
                            name="meta_title"
                            className="form-control"
                            {...register("meta_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category-title">
                            Meta Description
                          </label>
                          <input
                            type="text"
                            id="meta_description"
                            name="meta_description"
                            className="form-control"
                            {...register("meta_description")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="name">Category Name</label>
                          <input
                            type="text"
                            name="category"
                            className="form-control"
                            id="category"
                            required
                            {...register("p_category_name")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category-title">Category Title</label>
                          <input
                            type="text"
                            id="category-title"
                            name="cat_title"
                            className="form-control"
                            {...register("pcat_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category-title">
                            Category Subtitle
                          </label>
                          <input
                            type="text"
                            id="category-subtitle"
                            name="cat_subtitle"
                            className="form-control"
                            {...register("pcat_subtitle")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="short-desc">Short Description</label>
                          <textarea
                            name="short_desc"
                            id="short-desc"
                            className="form-control"
                            defaultValue={""}
                            {...register("pcat_short_desc")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="long-desc">Long Description</label>
                          <textarea
                            name="long_desc"
                            className="form-control"
                            id="long-desc"
                            defaultValue={""}
                            {...register("pcat_long_desc")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-4">
                        <label>Category Image</label>
                        <input
                          className="form-control"
                          type="file"
                          id="upload_image1"
                          name="upload_image1"
                          onChange={(e) => setCategoryImg(e.target.files[0])}
                        />
                        <div id="uploaded_image1" className="ml-auto pt-2" />
                        <div
                          className="modal"
                          id="image_modal1"
                          data-backdrop="static"
                          data-keyboard="false"
                          tabIndex={-1}
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="col-lg-12">
                                  <div id="image_demo1" />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary crop_image1"
                                >
                                  Save and upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddProductCategory;
