import React from "react";
import { Link } from "react-router-dom";

const AssignRoles = () => {
  return (
    <>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-outline">
                <div className="card-header bg-info">
                  <h3 className="card-title">Assign Role</h3>
                  <div className="card-tools"></div>
                </div>
                <form
                  id="quickForm"
                  method="post"
                  action="https://admin-bakumia.devifysolutions.pk/roles_add"
                  encType="multipart/form-data"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Role Title</label>
                          <input
                            type="text"
                            name="ut_name"
                            className="form-control "
                            required
                          />
                        </div>

                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                User Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="user"
                                    checked
                                    data-toggle="toggle"
                                    data-onstyle="success"
                                    data-offstyle="danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Products Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="products"
                                    data-bootstrap-switch
                                    defaultChecked
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Order Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="orders"
                                    data-bootstrap-switch
                                    defaultChecked
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Blogs
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="blogs"
                                    data-bootstrap-switch
                                    defaultChecked
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Role Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="role"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Notifications
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="notifications"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <div className="float-right">
                        <button
                          type="submit"
                          className="btn btn-info btn-custom"
                        >
                          <i className="fa fa-paper-plane" /> Submit
                        </button>
                      </div>
                      <Link to="/roles" className="btn btn-default">
                        <i className="fas fa-times" /> Discard
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AssignRoles;
