import React, { useEffect, useState } from "react";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { Link, useParams } from "react-router-dom";
import {
  addVariantImg,
  updateProductVariant,
} from "../../services/userServices";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const UpdateProductVariant = () => {
  const { p_v_id } = useParams();
  const [variantDetails, setVariantDetails] = useState([]);
  const [variantColors, setVariantColors] = useState([]);
  const [errors, setErrors] = useState("");

  const [variantImg, setVariantImg] = useState(null);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      product_id_fk: variantDetails.product_id_fk || "",
      p_v_id: variantDetails.p_v_id || "",
      p_v_name: variantDetails.p_v_name || "",
      product_weight: variantDetails.product_weight || "",
      product_price: variantDetails.product_price || "",
      discount_percent: variantDetails.discount_percent || 0,
      product_original_price: variantDetails.product_original_price || "0",
      total_quantity: variantDetails.total_quantity || "0",
      available_quantity: variantDetails.available_quantity || "0",
      color_id_fk: variantDetails.color_id_fk || "0",
      in_stock: variantDetails.in_stock || "0",
    },
  });

  useEffect(() => {
    setValue("product_id_fk", variantDetails.product_id_fk || "");
    setValue("p_v_id", variantDetails.p_v_id || "");
    setValue("p_v_name", variantDetails.p_v_name || "");
    setValue("product_weight", variantDetails.product_weight || "");
    setValue("product_price", variantDetails.product_price || "");
    setValue("discount_percent", variantDetails.discount_percent || 0);
    setValue("pcat_image", variantDetails.pcat_image || "");
    setValue(
      "product_original_price",
      variantDetails.product_original_price || ""
    );
    setValue("total_quantity", variantDetails.total_quantity || "");
    setValue("available_quantity", variantDetails.available_quantity || "");
    setValue("color_id_fk", variantDetails.color_id_fk || "");
    setValue("in_stock", variantDetails.in_stock || "");
  }, [variantDetails]);

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get(`/product-variant-by-id/${p_v_id}`)
      .then((response) => {
        setVariantDetails(response.data.data);
      })
      .catch((err) => setErrors(err.message));
  };

  useEffect(() => {
    apiClient
      .get(`/get-colors`)
      .then((response) => {
        setVariantColors(response.data.data);
      })
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    updateProductVariant(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating variant details.");
      });
  };

  const handleAddVariantImg = () => {
    addVariantImg(p_v_id, variantImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating variant image.");
      });
  };

  const deleteVariantImg = (p_img_id) => {
    apiClient
      .post(`/delete-varient-images?p_img_id=${p_img_id}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })

      .catch((error) => {
        toast.error("Error deleting variant image:", error);
      });
  };

  console.log(variantDetails);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Product Variant Slider Images</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12 mb-3">
                    <div className="card">
                      <div className="card-header">Current Images</div>
                      <div className="card-body">
                        <form>
                          <div className="row">
                            {variantDetails.varient_images &&
                              variantDetails.varient_images.map(
                                (vaiantImag) => (
                                  <>
                                    <div className="col-lg-2">
                                      <div
                                        className="pt-2 old_img"
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src={`${IMAGE_BASE_URL}/${vaiantImag && vaiantImag.image_path
                                            }`}
                                          height={100}
                                          className="img-fluid"
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                          }}
                                        >
                                          <Link
                                            className="text-left text-secondary"
                                            onClick={() =>
                                              deleteVariantImg(
                                                vaiantImag.p_img_id
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-times fa-3x"
                                              aria-hidden="true"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="form-group">
                                        <textarea
                                          name="rimg_alt_content[]"
                                          className="form-control"
                                          rows={3}
                                          placeholder="Enter Alt Content"
                                          defaultValue={
                                            vaiantImag &&
                                            vaiantImag.rimg_alt_content
                                          }
                                        />
                                        <input
                                          type="hidden"
                                          name="p_img_id[]"
                                          defaultValue={vaiantImag.p_img_id}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )
                              )}

                            {/* <div className="col-12 mb-3">
                              <div className="float-right">
                                <button
                                  type="submit"
                                  className="btn btn-info btn-custom"
                                >
                                  {" "}
                                  Update content
                                </button>
                                <a className="btn btn-info btn-custom" href="">
                                  {" "}
                                  Delete all
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(handleAddVariantImg)}>
                    <div className="row">
                      <div className="col-lg-12 mb-4">
                        <input
                          className="form-control"
                          type="file"
                          id="upload_slider_images"
                          name="upload_slider_images"
                          multiple
                          onChange={(e) => setVariantImg(e.target.files)}
                        />
                        <input type="hidden" name="slug" defaultValue="Blue" />
                      </div>
                      <div className="col-12">
                        <div className="float-right">
                          <button
                            type="submit"
                            className="btn btn-info btn-custom"
                          >
                            {" "}
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Edit Product Varient Details</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-md-12 mx-auto">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <input
                          type="hidden"
                          name="product_id_fk"
                          defaultValue={
                            variantDetails && variantDetails.product_id_fk
                          }
                          {...register("p_sub_category_name")}
                        />
                        <div className="col-md-6 my-2">
                          <label htmlFor>Variant Color</label>

                          <select
                            name="color_id_fk"
                            id="color_id_fk"
                            className="form-control"
                            defaultValue={
                              variantDetails && variantDetails.color_id_fk
                            }
                            {...register("color_id_fk")}
                          >
                            {variantColors &&
                              variantColors.map((color) => (
                                <option value={color.color_id}>
                                  {color.color_name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Name</label>
                          <input
                            type="text"
                            defaultValue={
                              variantDetails && variantDetails.p_v_name
                            }
                            placeholder="Enter Varient Name"
                            name="p_v_name"
                            className="form-control"
                            {...register("p_v_name")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Weight (Grams)</label>
                          <input
                            type="number"
                            defaultValue={
                              variantDetails && variantDetails.product_weight
                            }
                            placeholder="Enter Varient Weight"
                            name="product_weight"
                            className="form-control"
                            {...register("product_weight")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Original Price</label>
                          <input
                            type="number"
                            defaultValue={
                              variantDetails &&
                              variantDetails.product_original_price
                            }
                            placeholder="Enter Varient Original Price"
                            name="product_original_price"
                            className="form-control"
                            {...register("product_original_price")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Price</label>
                          <input
                            type="number"
                            defaultValue={
                              variantDetails && variantDetails.product_price
                            }
                            placeholder="Enter Varient Price"
                            name="product_price"
                            className="form-control"
                            {...register("product_price")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Discount Percent(%)</label>
                          <input
                            type="number"
                            defaultValue={
                              variantDetails && variantDetails.discount_percent
                            }
                            placeholder="Enter Varient Discount Percent"
                            name="discount_percent"
                            className="form-control"
                            {...register("discount_percent")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Total Quantity</label>
                          <input
                            type="number"
                            defaultValue={
                              variantDetails && variantDetails.total_quantity
                            }
                            placeholder="Enter Varient Total Quantity"
                            name="total_quantity"
                            className="form-control"
                            {...register("total_quantity")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Available Quantity</label>
                          <input
                            type="number"
                            defaultValue={
                              variantDetails &&
                              variantDetails.available_quantity
                            }
                            placeholder="Enter Varient Available Quantity"
                            name="available_quantity"
                            className="form-control"
                            {...register("available_quantity")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="form-group">
                            <label htmlFor="amount">In Stock</label>
                            <select
                              className="form-control"
                              name="is_active"
                              required
                              defaultValue={
                                variantDetails && variantDetails.in_stock
                              }
                              {...register("in_stock")}
                            >
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        <input
                          type="submit"
                          defaultValue="Update Varient"
                          className="btn btn-info btn-custom my-3 "
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProductVariant;
