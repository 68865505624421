import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import { addNotification } from "../../services/userServices";

const AddPushNotification = () => {
  const [notificationType, setNotificationType] = useState([]);
  const [userType, setUserType] = useState([]);
  const [productSubcategories, setProductSubcategories] = useState([]);
  const [productImg, setProductImg] = useState(null);

  const [errors, setErrors] = useState("");

  const userId = localStorage.getItem("userID");

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    apiClient
      .get("/get-noti-cate")
      .then((response) => setNotificationType(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    apiClient
      .get("/get-usertype")
      .then((response) => setUserType(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    addNotification(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/push-notifications");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding product.");
      });
  };

  console.log("Noti", userType);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-info card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Send Notification</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Select User Type</label>
                          <select
                            className="form-control select2bs4"
                            id="user_type"
                            name="user_type"
                            style={{ width: "100%" }}
                            required
                            {...register("ut_id_fk")}
                          >
                            <option value="">Select Option</option>
                            {userType.map((item) => (
                              <option value={item.ut_id}>{item.ut_name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Select Screen/Activity</label>
                          <select
                            className="form-control select2bs4"
                            name="activity_type"
                            style={{ width: "100%" }}
                            required
                            {...register("activity_type")}
                          >
                            <option value="">Select Option</option>
                            <option value="Shop">Shop</option>
                            <option value="Home">Home</option>
                            <option value="Notifications">Notifications</option>
                            <option value="Favourite">Favourite</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Select Notification Type</label>
                          <select
                            className="form-control select2bs4"
                            name="category"
                            style={{ width: "100%" }}
                            required
                            {...register("n_c_id_fk")}
                          >
                            <option value="">Select Option</option>
                            {notificationType.map((item) => (
                              <option value={item.n_c_id}>
                                {item.category_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="hidden"
                            name="user id"
                            value={userId}
                            {...register("sender_id_fk")}
                          />

                          <label htmlFor="exampleInputEmail1">
                            Notification Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            minLength={5}
                            maxLength={100}
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Ex: All Offers"
                            required
                            {...register("noti_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Notification Details
                          </label>
                          <textarea
                            name="detail"
                            className="form-control"
                            minLength={10}
                            maxLength={1500}
                            id="detail"
                            row={3}
                            placeholder="Ex: All Offers"
                            required
                            {...register("noti_desc")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                    <Link to="/push-notifications" className="btn btn-default">
                      <i className="fas fa-times" /> Discard
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPushNotification;
