import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addProduct } from "../../services/userServices";
import { toast } from "react-toastify";
import apiClient from "../../utils/api-client";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [productCategories, setPoductCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [productSubcategories, setProductSubcategories] = useState([]);
  const [productImg, setProductImg] = useState(null);

  const [errors, setErrors] = useState("");

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    apiClient
      .get("/get-product-cate")
      .then((response) => setPoductCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      apiClient
        .get(`/subcate-by-category/${selectedCategoryId}`)
        .then((response) => setProductSubcategories(response.data.data))
        .catch((err) => setErrors(err.message));
    }
  }, [selectedCategoryId]);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategoryId(categoryId); // Update selected category ID
  };

  const onSubmit = (formData) => {
    addProduct(formData, productImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/products");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding product.");
      });
  };
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Product Detail</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <label>Product Image</label>
                        <input
                          className="form-control"
                          type="file"
                          name="upload_main_image"
                          required
                          onChange={(e) => setProductImg(e.target.files[0])}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Keywords</label>
                              <input
                                type="text"
                                name="keywords"
                                className="form-control"
                                id="keywords"
                                placeholder="Kitchen cabnits"
                                required
                                {...register("keywords")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Meta Title</label>
                              <input
                                type="text"
                                name="meta_title"
                                className="form-control"
                                id="meta_title"
                                placeholder="Kitchen cabnits"
                                required
                                {...register("meta_title")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Meta Description</label>
                              <input
                                type="text"
                                name="meta_desc"
                                className="form-control"
                                id="meta_desc"
                                placeholder="Kitchen cabnits"
                                required
                                {...register("meta_desc")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Product Title</label>
                              <input
                                type="text"
                                name="product_name"
                                className="form-control"
                                id="product_name"
                                placeholder="Kitchen cabnits"
                                required
                                {...register("product_name")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="input-10">Alt Content</label>
                              <input
                                type="text"
                                name="alt_content"
                                className="form-control"
                                id="alt_content"
                                placeholder="Kitchen cabnits"
                                required
                                {...register("alt_content")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="inputDescription">
                              Product Description
                            </label>
                            <textarea
                              name="product_details"
                              id="product_details"
                              className="form-control"
                              rows={15}
                              required
                              defaultValue={""}
                              {...register("product_details")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">Select Category</label>
                          <select
                            id="inputStatus"
                            className="form-control category_id_fk"
                            name="category_id_fk"
                            required
                            {...register("category_id_fk")}
                            onChange={handleCategoryChange}
                          >
                            <option value="" hidden>
                              Select Category
                            </option>
                            {productCategories &&
                              productCategories.map((productCategory) => (
                                <option value={productCategory.p_category_id}>
                                  {productCategory.p_category_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-10">Sub Category</label>
                          <select
                            id="inputStatus"
                            className="form-control subcat_id_fk"
                            name="subcat_id_fk"
                            required
                            {...register("subcat_id_fk")}
                          >
                            <option value="" hidden>
                              Select Sub Category
                            </option>
                            {productSubcategories &&
                              productSubcategories.map((productSubcategory) => (
                                <option value={productSubcategory.s_cat_id}>
                                  {productSubcategory.p_sub_category_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">Youtube Video</label>
                          <select
                            id="youtubeVideoStatus"
                            className="form-control"
                            name="is_youtube_video"
                            required
                            {...register("is_youtube_video")}
                          >
                            <option value="" hidden>
                              Select Option
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-10">Youtube Video Url </label>
                          <input
                            type="text"
                            id="video_url"
                            name="video_url"
                            className="form-control"
                            {...register("youtube_video_url")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-10">Youtube Video ID </label>
                          <input
                            type="text"
                            id="youtube_video_id"
                            name="youtube_video_id"
                            className="form-control"
                            {...register("youtube_video_id")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">In Stock</label>
                          <select
                            id="in_stock"
                            className="form-control"
                            name="in_stock"
                            required
                            {...register("in_stock")}
                          >
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="input-6">Featured</label>
                          <select
                            id="is_featured"
                            className="form-control"
                            name="is_featured"
                            required
                            {...register("is_featured")}
                          >
                            <option value="" hidden>
                              Select Option
                            </option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-plus" /> Add Product
                      </button>
                    </div>
                    <a className="btn btn-default">
                      <i className="fas fa-times" /> Discard
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddProduct;
