import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";

const AllProducts = () => {
  const [productsList, setProductsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get("/get-all-product")
      .then((response) => setProductsList(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const deactivateProduct = (productId) => {
    apiClient
      .post(`/update-product-status?product_id=${productId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })
      .catch((error) => {
        toast.error("Error deactivating product:", error);
      });
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on search input change
  };

  const handleSearchFormSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to the first page on search submit
  };

  const filteredProducts = productsList.filter((product) => {
    return (
      product.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.category.p_category_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      product.subcategory.p_sub_category_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handleClick(i)}>
            {i}
          </button>
        </li>
      );
    }
    return buttons;
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3">
              <Link
                to="/add-product"
                className="btn btn-success btn-custom btn-sm"
              >
                <i className="fas fa-plus" /> Add New Product
              </Link>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-sm-8 d-flex align-items-center">
                      <h3 className="card-title m-1"> All Products</h3>
                    </div>
                    <div className="col-sm-4">
                      <form
                        className="msform"
                        onSubmit={handleSearchFormSubmit}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            name="search_text"
                            id="search_text"
                            className="form-control"
                            placeholder="Search product"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            required
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Title</th>
                          <th>Category</th>
                          <th>Sub Category</th>
                          <th>Availability</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((product) => (
                          <tr key={product.product_id} className="text-middle">
                            <td className="project-actions">
                              <Link
                                to={`/update-product/${product.product_id}`}
                                className="btn btn-info btn-sm btn-custom m-1"
                              >
                                <i className="fas fa-pencil-alt"></i> Edit
                              </Link>
                              {product.is_deleted < 1 ? (
                                <button
                                  className="btn btn-danger btn-custom btn-sm m-1"
                                  onClick={() =>
                                    deactivateProduct(product.product_id)
                                  }
                                >
                                  <i className="fas fa-trash"></i> Deactivate
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-custom btn-sm m-1"
                                  onClick={() =>
                                    deactivateProduct(product.product_id)
                                  }
                                >
                                  <i className="fas fa-plus"></i> Activate
                                </button>
                              )}
                            </td>
                            <td>{product.product_name}</td>
                            <td>{product.category.p_category_name}</td>
                            <td>{product.subcategory.p_sub_category_name}</td>
                            <td>
                              {product.in_stock > 0 ? (
                                <span className="badge badge-success p-2">
                                  In stock
                                </span>
                              ) : (
                                <span className="badge badge-danger p-2">
                                  Out of stock
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <nav>
                    <ul className="pagination justify-content-center">
                      {renderPaginationButtons()}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllProducts;

