import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateBlog } from "../../services/userServices";

const EditBlog = () => {
  const { b_id } = useParams();
  const [blogDetails, setBlogDetails] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [errors, setErrors] = useState([]);

  const [blogImg, setBlogImg] = useState(null);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      keywords: blogDetails.keywords || "",
      meta_title: blogDetails.meta_title || "",
      meta_desc: blogDetails.meta_desc || "",
      b_title: blogDetails.b_title || "",
      b_short_desc: blogDetails.b_short_desc || "",
      b_long_desc: blogDetails.b_long_desc || "",
      b_image: blogDetails.b_image || "",
      b_cid_fk: (blogDetails && blogDetails.b_cid_fk) || "",
      is_active: blogDetails.is_active || "",
      b_id: blogDetails.b_id || "",
    },
  });

  // Update the form values if they change
  useEffect(() => {
    setValue("keywords", blogDetails.keywords || "");
    setValue("meta_title", blogDetails.meta_title || "");
    setValue("meta_desc", blogDetails.meta_desc || "");
    setValue("b_title", blogDetails.b_title || "");
    setValue("b_short_desc", blogDetails.b_short_desc || "");
    setValue("b_long_desc", blogDetails.b_long_desc || "");
    setValue("b_cid_fk", (blogDetails && blogDetails.b_cid_fk) || "");
    setValue("is_active", blogDetails.is_active || "");
    setValue("b_id", blogDetails.b_id || "");
  }, [blogDetails]); // Triggered when producDetails changes

  useEffect(() => {
    apiClient
      .get(`get-single-blog/${b_id}`)
      .then((response) => setBlogDetails(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    apiClient
      .get("/get-all-blog-cat")
      .then((response) => setBlogCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const handleUpdateBlog = (formData) => {
    updateBlog(formData, blogImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating product details.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(handleUpdateBlog)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Update Blog</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="f_name">Blog Title</label>
                          <input
                            type="text"
                            name="b_title"
                            className="form-control"
                            id="b_title"
                            defaultValue={blogDetails && blogDetails.b_title}
                            required
                            {...register("b_title")}
                          />
                          <input
                            type="hidden"
                            name="b_id"
                            className="form-control"
                            id="b_id"
                            defaultValue={blogDetails && blogDetails.b_id}
                            required
                            {...register("b_id")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="f_name">Keywords</label>
                        <div className="form-group">
                          <input
                            type="text"
                            name="keywords"
                            className="form-control"
                            id="keywords"
                            defaultValue={blogDetails && blogDetails.keywords}
                            required
                            {...register("keywords")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="p_price">Meta Title</label>
                          <input
                            type="text"
                            name="meta_title"
                            className="form-control"
                            id="meta_title"
                            defaultValue={blogDetails && blogDetails.meta_title}
                            required
                            {...register("meta_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="p_final_price">
                            Meta Description
                          </label>
                          <input
                            type="text"
                            name="meta_desc"
                            className="form-control"
                            id="meta_desc"
                            defaultValue={blogDetails && blogDetails.meta_desc}
                            required
                            {...register("meta_desc")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="c_id_fk">Choose Category</label>
                          <select
                            className="form-control"
                            name="b_cid_fk"
                            defaultValue={blogDetails && blogDetails.c_id_fk}
                            {...register("c_id_fkc")}
                          >
                            {blogCategories &&
                              blogCategories.map((blogCategory) => (
                                <option value={blogCategory.bcat_id}>
                                  {blogCategory.bcat_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label>Blog Image</label>
                        <input
                          className="form-control"
                          type="file"
                          id="upload_image1"
                          name="upload_image1"
                          required
                          onChange={(e) => setBlogImg(e.target.files[0])}
                        />
                        <div className="pt-2 old_img">
                          <img
                            src={`${IMAGE_BASE_URL}/${blogDetails && blogDetails.b_image
                              }`}
                            height={100}
                          />
                        </div>
                        <div id="uploaded_image1" className="ml-auto pt-2" />
                        <div
                          className="modal"
                          id="image_modal1"
                          data-backdrop="static"
                          data-keyboard="false"
                          tabIndex={-1}
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="col-lg-12 mx-auto">
                                  <div id="image_demo1" />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-info crop_image1"
                                >
                                  Save and upload
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="p_description">
                            Long Description
                          </label>
                          <textarea
                            name="b_long_desc"
                            className="form-control"
                            id="b_long_desc"
                            required
                            rows="10"
                            defaultValue={
                              blogDetails && blogDetails.b_long_desc
                            }
                            {...register("b_long_desc")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditBlog;
