import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import { Link } from "react-router-dom";
import { addProductVariant } from "../../services/userServices";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ProductsVariants = ({ productId }) => {
  const [productVariants, setProductVariants] = useState([]);
  const [variantColors, setVariantColors] = useState([]);
  const [errors, setErrors] = useState("");
  const [variantImg, setVariantImg] = useState(null);

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchProductsList();
  }, [productId]);

  const fetchProductsList = () => {
    apiClient
      .get(`/product-variant/${productId}`)
      .then((response) => {
        setProductVariants(response.data.data);
      })
      .catch((err) => setErrors(err.message));
  };

  useEffect(() => {
    apiClient
      .get(`/get-colors`)
      .then((response) => {
        setVariantColors(response.data.data);
      })
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    addProductVariant(formData, variantImg, productId)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding variant.");
      });
  };

  const deactivateVariant = (variantId) => {
    apiClient
      .post(`/update-product-varient-status?p_v_id=${variantId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })

      .catch((error) => {
        toast.error("Error deactivating variant:", error);
      });
  };

  return (
    <div className="col-md-12">
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">Product Varients</h3>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
              title="Collapse"
            >
              <i className="fas fa-plus" />
            </button>
          </div>
        </div>
        <div className="card-body">
          <button
            className="float-right btn btn-info btn-custom my-3"
            data-toggle="modal"
            data-target="#addVarient"
          >
            Add New Varient
          </button>
          {/* Modal For Add Varient*/}
          <div
            className="modal fade"
            id="addVarient"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add Product Varient
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="col-md-12">
                      <input type="hidden" name="p_id" value={productId} />
                      <div className="row">
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Images</label>
                          <input
                            type="file"
                            name="images[]"
                            multiple
                            className="form-control"
                            required
                            onChange={(e) => setVariantImg(e.target.files)}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Alt Content</label>
                          <input
                            type="text"
                            placeholder="Enter Varient Alt Content"
                            name="rimg_alt_content"
                            className="form-control"
                            required
                            {...register("rimg_alt_content")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Color</label>
                          <select
                            name="color_id_fk"
                            id="color_id_fk"
                            className="form-control"
                            required
                            {...register("color_id_fk")}
                          >
                            <option value selected disabled>
                              Select Color
                            </option>
                            {variantColors &&
                              variantColors.map((color) => (
                                <option value={color.color_id}>
                                  {color.color_name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Name</label>
                          <input
                            type="text"
                            placeholder="Enter Varient Name"
                            name="p_v_name"
                            className="form-control"
                            required
                            {...register("p_v_name")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Weight (Grams)</label>
                          <input
                            type="number"
                            placeholder="Enter Varient Weight"
                            name="product_weight"
                            className="form-control"
                            required
                            {...register("product_weight")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Original Price</label>
                          <input
                            type="number"
                            placeholder="Enter Varient Original Price"
                            name="product_original_price"
                            className="form-control"
                            required
                            {...register("product_original_price")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Price</label>
                          <input
                            type="number"
                            placeholder="Enter Varient Price"
                            name="product_price"
                            className="form-control"
                            required
                            {...register("product_price")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Discount Percent (%)</label>
                          <input
                            input
                            type="number"
                            placeholder="Enter Varient Discount Percent"
                            name="discount_percent"
                            className="form-control"
                            required
                            {...register("discount_percent")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Total Quantity</label>
                          <input
                            type="number"
                            placeholder="Enter Varient Total Quantity"
                            name="total_quantity"
                            className="form-control"
                            required
                            {...register("total_quantity")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <label htmlFor>Varient Available Quantity</label>
                          <input
                            type="number"
                            placeholder="Enter Varient Available Quantity"
                            name="available_quantity"
                            className="form-control"
                            required
                            {...register("available_quantity")}
                          />
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="form-group">
                            <div className="row ">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="input-6">In Stock</label>
                                  <select
                                    id="in_stock"
                                    className="form-control"
                                    name="in_stock"
                                    required
                                    {...register("in_stock")}
                                  >
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <input
                      type="submit"
                      className="btn btn-info btn-custom"
                      defaultValue="Add Varient"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="table ">
            <table className="table " id="myTable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Available Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {productVariants &&
                  productVariants.map((variant) => (
                    <tr key={variant.p_v_id}>
                      <td>{variant.p_v_id}</td>
                      <td>{variant.p_v_name}</td>
                      <td>{variant.product_price}</td>
                      <td className>{variant.available_quantity}</td>
                      <td>
                        <Link
                          to={`/update-variant/${variant.p_v_id}`}
                          className="btn btn-info btn-sm m-1"
                        >
                          <i className="fa fa-edit" aria-hidden="true" /> Edit
                        </Link>

                        {variant.is_deleted < 1 ? (
                          <button
                            className="btn btn-danger btn-custom btn-sm m-1"
                            onClick={() => deactivateVariant(variant.p_v_id)}
                          >
                            <i className="fas fa-trash"></i> Deavtive
                          </button>
                        ) : (
                          <button
                            className="btn btn-success btn-custom btn-sm m-1"
                            onClick={() => deactivateVariant(variant.p_v_id)}
                          >
                            <i className="fas fa-plus"></i> Activate
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsVariants;
