import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import apiClient from "../utils/api-client";
import { useParams } from "react-router-dom";
import { updatePromo } from "../services/userServices";
import { toast } from "react-toastify";

const UpdatePromoCode = () => {
  const { pc_id } = useParams();
  const [promoCode, setPromoCode] = useState([]);
  const [errors, setErrors] = useState("");

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      pc_id: promoCode.pc_id || "",
      promo_code: promoCode.promo_code || "",
      discounted_value: promoCode.discounted_value || "",
      order_value: promoCode.order_value || "",
      is_percentage: promoCode.is_percentage || "",
    },
  });

  useEffect(() => {
    setValue("pc_id", promoCode.pc_id || "");
    setValue(".promo_code", promoCode.promo_code || "");
    setValue("discounted_value", promoCode.discounted_value || "");
    setValue("order_value", promoCode.order_value || "");
    setValue("is_percentage", promoCode.is_percentage || "");
  }, [promoCode]);

  useEffect(() => {
    apiClient
      .get(`/get-single-promo-code/${pc_id}`)
      .then((response) => setPromoCode(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    updatePromo(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating promo.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Update Promo Code</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Enter Promo Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="promo_code"
                            name="promo_code"
                            {...register("promo_code")}
                            defaultValue={promoCode.promo_code}
                          />
                          <input
                            type="hidden"
                            name="pc_id"
                            id="pc_id"
                            {...register("pc_id")}
                            defaultValue={promoCode.pc_id}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>Discounted Value</label>
                        <input
                          type="text"
                          className="form-control"
                          id="discounted_value"
                          name="discounted_value"
                          {...register("discounted_value")}
                          defaultValue={promoCode.discounted_value}
                        />
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Order Value</label>
                          <input
                            type="text"
                            className="form-control"
                            id="order_value"
                            name="order_value"
                            {...register("order_value")}
                            defaultValue={promoCode.order_value}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Is Percentage</label>
                          <select
                            className="form-control"
                            name="is_percentage"
                            required
                            {...register("is_percentage")}
                          >
                            <option value="">Select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdatePromoCode;
