import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "bootstrap";
import UserContext from "../contexts/UserContext";

const Logout = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem("userID");
      toast.success("Logout successful");
      setUser(null);
      navigate("/login");
    };

    const handleLogout = () => {
      const myModal = new Modal(document.getElementById("logoutModal"), {
        keyboard: false,
        backdrop: "static",
      });
      myModal.show();

      const confirmButton = document.getElementById("confirmLogout");
      confirmButton.addEventListener("click", () => {
        logoutUser();
        myModal.hide();
      });

      const cancelButton = document.getElementById("cancelLogout");
      cancelButton.addEventListener("click", () => {
        myModal.hide();
        navigate("/login");
      });
    };

    handleLogout();
  }, [setUser, navigate]);

  return (
    <div
      className="modal fade"
      id="logoutModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div
            className="modal-header"
            style={{ backgroundColor: "#800080", color: "#fff" }}
          >
            <h5 className="modal-title" id="exampleModalLabel ">
              Logout Confirmation
            </h5>
            <button
              type="button"
              className="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Are you sure you want to logout?</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary  border-0"
              id="cancelLogout"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-warning border-0"
              id="confirmLogout"
              style={{ backgroundColor: "#800080", color: "#fff" }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
