import React, { useState, useEffect } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import apiClient from "../../utils/api-client";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState("");

  useEffect(() => {
    apiClient
      .get("/get-all-users")
      .then((response) => setUsers(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered user data based on search query
  const filteredUsers = users.filter((user) => {
    return (
      user.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.address.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <>
      {/* <ContentHeader title="Users" /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-sm-8 d-flex align-items-center">
                      <h3 className="card-title m-1">All Users</h3>
                    </div>
                    <div className="col-sm-4">
                      <form>
                        {/* Search Input */}
                        <div className="input-group">
                          <input
                            type="text"
                            name="search_text"
                            id="search_text"
                            className="form-control"
                            placeholder="Search users"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* Table */}
                  <div className="table-responsive">
                    <table
                      id="table-to-export"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Mapping over filtered data */}
                        {filteredUsers.map((user) => (
                          <tr key={user.user_id}>
                            <td>{user.full_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.address}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
