import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UpdateShippingCharge } from "../../services/userServices";

const UpdateShippingCharges = () => {
  const { wc_id } = useParams();
  const [shippingCharges, setShippingCharges] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      wc_id: shippingCharges.wc_id || "",
      city_id_fk: shippingCharges.city_id_fk || "",
      from_weight: shippingCharges.from_weight || "",
      to_weight: shippingCharges.to_weight || "",
      wc_charges: shippingCharges.wc_charges || "",
    },
  });

  useEffect(() => {
    setValue("wc_id", shippingCharges.wc_id || "");
    setValue("city_id_fk", shippingCharges.city_id_fk || "");
    setValue("from_weight", shippingCharges.from_weight || "");
    setValue("to_weight", shippingCharges.to_weight || "");
    setValue("wc_charges", shippingCharges.wc_charges || "");
  }, [shippingCharges]);

  useEffect(() => {
    apiClient
      .get(`/get-single-shipping-charges/${wc_id}`)
      .then((response) => setShippingCharges(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    apiClient
      .get("/get-cities")
      .then((response) => setCities(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const handleUpdateShippingCharges = (formData) => {
    UpdateShippingCharge(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/shipping-charges");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating shipping chages.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info card-outline">
                <div className="card-header ">
                  <div className="row">
                    <div className="col-sm-8">
                      <h3 className="card-title">Edit City</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <form onSubmit={handleSubmit(handleUpdateShippingCharges)}>
                      <div className="row">
                        <div className="col-md-3">
                          <label>Choose City</label>
                          <div className="form-group">
                            <select
                              id="inputStatus"
                              className="form-control"
                              required
                              defaultValue={
                                shippingCharges && shippingCharges.city_id_fk
                              }
                              {...register("city_id_fk")}
                            >
                              {cities &&
                                cities.map((city) => (
                                  <option
                                    key={city.city_id}
                                    value={city.city_id}
                                  >
                                    {city.city_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label>From Weight</label>
                          <div className="form-group">
                            <input
                              type="hidden"
                              name="wc_id"
                              className="form-control"
                              id="wc_id"
                              defaultValue={shippingCharges.wc_id}
                              required
                              {...register("wc_id")}
                            />
                            <input
                              type="text"
                              name="from_weight"
                              className="form-control"
                              id="from_weight"
                              defaultValue={shippingCharges.from_weight}
                              required
                              {...register("from_weight")}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label>To Weight</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="to_weight"
                              className="form-control"
                              id="to_weight"
                              defaultValue={shippingCharges.to_weight}
                              required
                              {...register("to_weight")}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label>Charges</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="charges"
                              className="form-control"
                              id="charges"
                              defaultValue={shippingCharges.wc_charges}
                              required
                              {...register("wc_charges")}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label>Action</label>
                          <div className="form-group">
                            <div className="form-group">
                              <div className="form-group">
                                <button type="submit" className="btn btn-info">
                                  <i className="fas fa-pencil-alt" /> Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateShippingCharges;
