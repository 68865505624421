import React from "react";
import { Link } from "react-router-dom";

const UpdateRole = () => {
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-info card-outline">
                <div className="card-header">
                  <h3 className="card-title">Edit Role</h3>
                  <span className="text-right d-inline-block"></span>
                  <div className="card-tools"></div>
                </div>
                <form
                  id="quickForm"
                  method="post"
                  action=""
                  encType="multipart/form-data"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Role Title</label>
                          <input
                            type="text"
                            name="ut_name"
                            className="form-control"
                            defaultValue="Admin"
                            disabled
                          />
                          <input type="hidden" name="ut_id" defaultValue={1} />
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                User Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="user"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-off-color="danger"
                                    data-on-color="success"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Product Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="products"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Order Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="orders"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Blogs
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="blogs"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Role Management
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="role"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="row">
                              <label htmlFor="input-10" className="col-md-6">
                                Notifications
                              </label>
                              <div className="col-md-6">
                                <div className="bt-switch">
                                  <input
                                    type="checkbox"
                                    name="notifications"
                                    defaultChecked
                                    data-bootstrap-switch
                                    data-on-color="success"
                                    data-off-color="danger"
                                    data-on-text="YES"
                                    data-off-text="NO"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <div className="float-right">
                        <button
                          type="submit"
                          className="btn btn-info btn-custom"
                        >
                          <i className="fa fa-paper-plane" /> Submit
                        </button>
                      </div>
                      <Link to="/roles" className="btn btn-default">
                        <i className="fas fa-times" /> Discard
                      </Link>
                    </div>
                  </div>
                </form>
                {/* /.card */}
              </div>
              {/*/.col (left) */}
              {/* right column */}
              <div className="col-md-6"></div>
              {/*/.col (right) */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
      </section>
    </>
  );
};

export default UpdateRole;
