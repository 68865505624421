import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../utils/api-client";

const AllOrders = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [allOrders, setAllOrders] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    apiClient
      .get("get-all-order")
      .then((response) => setAllOrders(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const filteredOrders =
    selectedStatus === "All"
      ? allOrders
      : allOrders.filter((order) => order.status.status === selectedStatus);

  console.log("Orders:", allOrders);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-sm-8 d-flex align-items-center">
                      <h3 className="card-title m-1">All Orders</h3>
                    </div>
                    <div className="col-sm-4 dropdown d-flex justify-content-end">
                      <button
                        className="btn btn-dark dropdown-toggle w-50 d-flex justify-content-between align-items-center"
                        type="button"
                        id="statusDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {selectedStatus === "All"
                          ? "All Orders"
                          : selectedStatus}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="statusDropdown"
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => setSelectedStatus("All")}
                        >
                          All Orders
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => setSelectedStatus("Delivered")}
                        >
                          Delivered Orders
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => setSelectedStatus("Pending")}
                        >
                          Pending Orders
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => setSelectedStatus("Canceled")}
                        >
                          Cancelled Orders
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>ID</th>
                          <th>Status</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Price</th>
                          <th>Shipping Charges</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredOrders.map((order) => (
                          <tr key={order.id}>
                            <td>
                              <div className="btn-group">
                                <Link
                                  className="btn btn-success"
                                  to={`/order-details/${order.order_id}`}
                                >
                                  View Details
                                </Link>

                                <div className="dropdown-menu" role="menu">
                                  <Link
                                    className="dropdown-item"
                                    to={`/order-details/${order.order_id}`}
                                  >
                                    View
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to={`/order-invoice/${order.order_id}`}
                                  >
                                    Invoice
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>{order.order_id}</td>
                            <td>
                              <span
                                className={`badge ${
                                  order.status.status === "Delivered"
                                    ? "badge-success"
                                    : order.status.status === "Pending"
                                    ? "badge-warning"
                                    : order.status.status === "Canceled"
                                    ? "badge-danger"
                                    : "badge-info"
                                } p-2`}
                              >
                                {order.status.status.charAt(0).toUpperCase() +
                                  order.status.status.slice(1)}
                              </span>
                            </td>
                            <td>{order.user && order.user.full_name}</td>
                            <td>{order.receiver_phone}</td>
                            <td>{order.order_price}</td>
                            <td>{order.shipping_charges}</td>
                            <td>{order.transaction.transaction_amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllOrders;
