import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";

const AllBlogs = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = () => {
    apiClient
      .get("/get-all-blog")
      .then((response) => setAllBlogs(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const deactivateBlog = (blogId) => {
    apiClient
      .post(`/update-blog-status?b_id=${blogId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchBlogs();
      })
      .catch((error) => {
        toast.error("Error try again!", error);
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <h3 className="card-title m-1"> All Articles</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Article Title</th>
                          <th>Published Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allBlogs &&
                          allBlogs.map((item) => (
                            <tr key={item.b_id}>
                              <td className="project-actions">
                                <Link
                                  className="btn btn-primary btn-custom btn-sm m-1"
                                  to={`/view-blog/${item.b_id}`}
                                >
                                  <i className="fas fa-eye"></i> View
                                </Link>
                                <Link
                                  className="btn btn-info btn-custom btn-sm m-1"
                                  to={`/edit-blog/${item.b_id}`}
                                >
                                  <i className="fas fa-pencil-alt"></i> Edit
                                </Link>
                                {item.is_active > 0 ? (
                                  <button
                                    className="btn btn-danger btn-custom btn-sm m-1"
                                    onClick={() => deactivateBlog(item.b_id)}
                                  >
                                    <i className="fas fa-trash"></i> Deavtive
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-custom btn-sm m-1"
                                    onClick={() => deactivateBlog(item.b_id)}
                                  >
                                    <i className="fas fa-plus"></i> Active
                                  </button>
                                )}
                              </td>
                              <td>{item.b_title}</td>
                              <td>{item.b_datetime}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllBlogs;
