import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentHeader from "../components/ContentHeader/ContentHeader";
import apiClient from "../utils/api-client";
import { useForm } from "react-hook-form";
import { addSitemap } from "../services/userServices";
import { toast } from "react-toastify";

const Sitemap = () => {
  const [allSitemap, setAllSitemap] = useState([]);
  const [errors, setErrors] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get(`/get-all-sitemap`)
      .then((response) => setAllSitemap(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const onSubmit = (formData) => {
    addSitemap(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding sitemap.");
      });
  };

  const deactivateSitemap = (s_id) => {
    apiClient
      .post(`/update-sitemap-status?s_id=${s_id}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchProductsList();
      })
      .catch((error) => {
        toast.error("Error deactivating product:", error);
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allSitemap.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(allSitemap.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handleClick(i)}>
            {i}
          </button>
        </li>
      );
    }
    return buttons;
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-1 ">
              <button
                className="btn btn-info btn-sm m-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fas fa-plus" /> Add New Url
              </button>
              {/* <button
                className="btn btn-success  btn-sm"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                <i className="fas fa-plus" /> Add All Urls
              </button> */}
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <h3 className="card-title">All URLs</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus text-white" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Title</th>
                          <th>URL</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((sitemap) => (
                            <tr key={sitemap.s_id}>
                              <td className="project-actions">
                                <Link
                                  className="btn btn-info btn-sm btn-custom"
                                  to={`/update-sitemap/${sitemap.s_id}`}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                  Edit
                                </Link>
                                {sitemap.s_status > 0 ? (
                                  <button
                                    className="btn btn-danger btn-custom btn-sm m-1"
                                    onClick={() =>
                                      deactivateSitemap(sitemap.s_id)
                                    }
                                  >
                                    <i className="fas fa-trash"></i> Deavtive
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success btn-custom btn-sm m-1"
                                    onClick={() =>
                                      deactivateSitemap(sitemap.s_id)
                                    }
                                  >
                                    <i className="fas fa-plus"></i> Activate
                                  </button>
                                )}
                              </td>
                              <td>{sitemap.s_title}</td>
                              <td>{sitemap.s_url}</td>
                              <td>
                                {sitemap.s_status > 0 ? (
                                  <span className="badge badge-success p-2">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-danger p-2">
                                    Deactive
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <nav>
                    <ul className="pagination justify-content-center">
                      {renderPaginationButtons()}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Sitemap Detail Modals */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content border">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info m-0">
                      <div className="card-header">
                        <h3 className="card-title">Sitemap Detail</h3>
                        <div className="card-tools">
                          <button
                            type="button"
                            className="btn btn-tool"
                            title="Collapse"
                            data-dismiss="modal"
                          >
                            <i className="fas fa-minus" />
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <label>Title</label>
                            <input
                              className="form-control"
                              type="text"
                              name="s_title"
                              placeholder="eg. Portable Mini .."
                              {...register("s_title")}
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <label>URL</label>
                            <input
                              className="form-control"
                              type="text"
                              name="s_url"
                              placeholder="eg. /home-improvement/portable-mini-washing-machine/"
                              {...register("s_url")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer m-0">
                        <div className="float-right">
                          <button
                            type="submit"
                            className="btn btn-info btn-custom"
                          >
                            <i className="fas fa-plus" /> Add Sitemap
                          </button>
                        </div>
                        <Link data-dismiss="modal" className="btn btn-default">
                          <i className="fas fa-times" /> Discard
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Add all Urls Detail Modals
        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content border">
              <form method="post" action="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info m-0">
                      <div className="card-header">
                        <h3 className="card-title">Add all Urls</h3>
                        <div className="card-tools">
                          <button
                            type="button"
                            className="btn btn-tool"
                            title="Collapse"
                            data-dismiss="modal"
                          >
                            <i className="fas fa-minus" />
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <p>
                            Are you sure you want to add all urls to site map ?
                          </p>
                        </div>
                      </div>
                      <div className="card-footer m-0">
                        <div className="float-right">
                          <button
                            type="submit"
                            className="btn btn-info btn-custom"
                          >
                            <i className="fas fa-plus" /> Add all Urls to
                            Sitemap
                          </button>
                        </div>
                        <Link className="btn btn-default" data-dismiss="modal">
                          <i className="fas fa-times" /> Discard
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Sitemap;
