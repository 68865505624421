import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";

const Reviews = () => {
  const [allReview, setAllReview] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    apiClient
      .get("/get-product-reviews")
      .then((response) => {
        setAllReview(response.data.data || []); // Ensure reviews is initialized as an array
      })
      .catch((err) => {
        setError("Error fetching reviews: " + err.message);
      });
  }, []);

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title"> All Reviews</h3>
              </div>
              <div className="card-body">
                {error && <div className="alert alert-info">{error}</div>}
                {Array.isArray(allReview) && allReview.length === 0 ? ( // Check if reviews is an array
                  <p className="text-center">No Data Available</p>
                ) : (
                  <div className="table-responsive">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Review</th>
                          <th>Ratings</th>
                          <th>User Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allReview.length > 0 &&
                          allReview.map((item) => (
                            <tr key={item.pr_id}>
                              <td>
                                {item.product && item.product.product_name}
                              </td>
                              <td>{item.reviews}</td>
                              <td>{item.rating}</td>
                              <td>{item.user && item.user.full_name}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
