import React from "react";
import { Link } from "react-router-dom";

const SmallBox = ({ count, text, icon, link, bgColor }) => {
  return (
    <div className={`small-box ${bgColor}`}>
      <div className="inner">
        <h3>{count}</h3>

        <p>{text}</p>
      </div>
      <div className="icon">
        <i className={`${icon}`} />
      </div>
      <Link to={link} className="small-box-footer">
        More info <i className="fas fa-arrow-circle-right" />
      </Link>
    </div>
  );
};

export default SmallBox;
