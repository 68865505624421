import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { orderStatus } from "../../services/userServices";

const OrderDetails = () => {
  const { order_id } = useParams();
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [errors, setErrors] = useState("");

  // const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get(`/get-order-details/${order_id}`)
      .then((response) => setOrderDetails(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const onSubmit = (statusId) => {
    orderStatus(order_id, statusId)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while changing order status.");
      });
  };

  const handleStatusChange = (statusId) => {
    setSelectedStatus(statusId);
    onSubmit(statusId); // Call onSubmit directly
  };

  console.log("selectedStatus:", selectedStatus);
  return (
    <>
      <div>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title"> Order Detail</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Customer Name :</h6>
                      <p className="d-inline">
                        {" "}
                        {orderDetails.user && orderDetails.user.full_name}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Customer Phone :</h6>
                      <p className="d-inline"> {orderDetails.receiver_phone}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Customer Email : </h6>
                      <p className="d-inline">
                        {" "}
                        {orderDetails.user && orderDetails.user.email}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Order Price :</h6>
                      <p className="d-inline"> Rs {orderDetails.order_price}</p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Discounted Value :</h6>
                      <p className="d-inline">
                        Rs {orderDetails.discounted_value}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Shipping Charges :</h6>
                      <p className="d-inline">
                        Rs {orderDetails.shipping_charges}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Order Status : </h6>{" "}
                      <p className="d-inline badge badge-info">
                        {orderDetails.status && orderDetails.status.status}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Total Price : </h6>{" "}
                      <p className="d-inline badge badge-info">
                        {" "}
                        {orderDetails.transaction &&
                          orderDetails.transaction.transaction_amount}
                      </p>
                    </div>
                    <div className="col-md-4 mb-3">
                      <h6 className="d-inline">Order ID : </h6>{" "}
                      <p className="d-inline badge badge-info">
                        {" "}
                        {orderDetails.order_id}
                      </p>
                    </div>
                    <div className="col-md-8 mb-3">
                      <h6 className="d-inline">Address : </h6>{" "}
                      <p className="d-inline badge badge-info">
                        {" "}
                        {orderDetails.shipping_address}
                        {", "}
                        {orderDetails.city && orderDetails.city.city_name}
                      </p>
                    </div>
                    <div className="col-md-4">
                      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                      <input
                        type="hidden"
                        name="order_id"
                        className="form-control"
                        id="order_id"
                        value={orderDetails.order_id && orderDetails.order_id}
                        // {...register("order_id")}
                      />
                      <div className="dropdown">
                        <button
                          className="btn btn-dark btn-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Change Order Status
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(1)}
                            >
                              Pending
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(2)}
                            >
                              In Process
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(3)}
                            >
                              Delivered
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(4)}
                            >
                              Canceled
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Hidden input field to store the selected status */}
                      <input
                        type="hidden"
                        name="status_id_fk"
                        value={selectedStatus}
                        // {...register("status_id_fk")}
                      />

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title"> Product Details</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsvie">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Product Image</th>
                          <th>Name</th>
                          <th>Color</th>
                          <th>Category</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails &&
                          orderDetails.order_detail &&
                          orderDetails.order_detail.map((order) => (
                            <tr key={order.order_details_id}>
                              <td>
                                <img
                                  src={`${IMAGE_BASE_URL}/${
                                    order.single_varient &&
                                    order.single_varient.product &&
                                    order.single_varient.product.product_image
                                  }`}
                                  className="order_image"
                                  height={60}
                                  width={60}
                                />
                              </td>
                              <td>
                                {order.single_varient &&
                                  order.single_varient.product &&
                                  order.single_varient.product.product_name}
                              </td>
                              <td>
                                {order &&
                                  order.single_varient &&
                                  order.single_varient.p_v_name}
                              </td>
                              <td>
                                {order.single_varient &&
                                  order.single_varient.product &&
                                  order.single_varient.product.category &&
                                  order.single_varient.product.category
                                    .p_category_name}
                              </td>
                              <td>
                                {order &&
                                  order.single_varient &&
                                  order.single_varient.product_price}
                              </td>
                              <td>{order && order.quantity}</td>
                              <td>
                                {order &&
                                  order.single_varient &&
                                  order.single_varient.product_price *
                                    order.quantity}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderDetails;
