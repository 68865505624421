import React from "react";
import { NavLink } from "react-router-dom";

const MenuItem = ({ title, icon, link }) => {
  return (
    <>
      <li className="nav-item ">
        <NavLink to={link} className="nav-link all-links">
          <i className={icon} />
          <p>{title}</p>
        </NavLink>
      </li>
    </>
  );
};

export default MenuItem;
