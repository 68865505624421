import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { addShippingCharge } from "../../services/userServices";
import { toast } from "react-toastify";

const MainComponent = () => {
  const [shippingCharges, setShippingCharges] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState([]);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    fetchProductsList();
  }, []);

  const fetchProductsList = () => {
    apiClient
      .get("/get-shipping-charges")
      .then((response) => setShippingCharges(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  useEffect(() => {
    apiClient
      .get("/get-cities")
      .then((response) => setCities(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    addShippingCharge(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchProductsList();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding  shipping charges.");
      });
  };

  console.log(shippingCharges);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-right mb-3">
              <button
                type="button"
                className="btn btn-info btn-custom btn-sm"
                data-toggle="modal"
                data-target="#modal-default"
              >
                <i className="fas fa-plus" /> Add city
              </button>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form id="quickForm" method="post" action="">
                    {shippingCharges &&
                      shippingCharges.map((item) => (
                        <div className="row">
                          <div className="col-md-3">
                            <label>Choose City</label>
                            <div className="form-group">
                              <select
                                id="inputStatus"
                                className="form-control"
                                required
                                defaultValue={item && item.city_id_fk}
                                disabled
                              >
                                {cities &&
                                  cities.map((city) => (
                                    <option
                                      key={city.city_id}
                                      value={city.city_id}
                                    >
                                      {city.city_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>From Weight</label>
                            <div className="form-group">
                              <input
                                type="hidden"
                                name="wc_id"
                                className="form-control"
                                id="wc_id"
                                defaultValue={item.wc_id}
                                required
                                disabled
                              />
                              <input
                                type="text"
                                name="from_weight"
                                className="form-control"
                                id="from_weight"
                                defaultValue={item.from_weight}
                                required
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label>To Weight</label>
                            <div className="form-group">
                              <input
                                type="text"
                                name="to_weight"
                                className="form-control"
                                id="to_weight"
                                defaultValue={item.to_weight}
                                required
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label>Charges</label>
                            <div className="form-group">
                              <input
                                type="text"
                                name="charges"
                                className="form-control"
                                id="charges"
                                defaultValue={item.wc_charges}
                                required
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label>Action</label>
                            <div className="form-group">
                              <NavLink
                                to={`/update-shipping-charges/${item.wc_id}`}
                                className="btn btn-info"
                              >
                                <i className="fas fa-pencil-alt" /> Edit
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      ))}
                  </form>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-header bg-info border-0">
                      <h4 className="modal-title">Add city charges detail</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Choose City</label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="city_id_fk"
                              required
                              {...register("city_id_fk")}
                            >
                              {cities &&
                                cities.map((city) => (
                                  <option
                                    key={city.city_id}
                                    value={city.city_id}
                                  >
                                    {city.city_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>From Weight</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="from_weight"
                              className="form-control"
                              id="from_weight"
                              required
                              {...register("from_weight")}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>To Weight</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="to_weight"
                              className="form-control"
                              id="to_weight"
                              required
                              {...register("to_weight")}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>Charges</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="charges"
                              className="form-control"
                              id="charges"
                              required
                              {...register("wc_charges")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainComponent;
