import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import TextEditor from "../../components/TextEditor/TextEditor";
import { useForm } from "react-hook-form";
import { addBlog } from "../../services/userServices";
import { toast } from "react-toastify";
import apiClient from "../../utils/api-client";

const AddNewBlog = () => {
  const [blogCategories, setBlogCategories] = useState([]);
  const [errors, setErrors] = useState([]);
  const [blogImg, setBlogImg] = useState(null);

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    apiClient
      .get("/get-all-blog-cat")
      .then((response) => setBlogCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    addBlog(formData, blogImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding blog.");
      });
  };

  console.log("blog cate:", blogCategories);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Add New Blog</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="f_name">Blog Title</label>
                          <input
                            type="text"
                            name="b_title"
                            className="form-control"
                            id="b_title"
                            required
                            {...register("b_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="f_name">Keywords</label>
                        <div className="form-group">
                          <input
                            type="text"
                            name="keywords"
                            className="form-control"
                            id="keywords"
                            required
                            {...register("keywords")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="p_price">Meta Title</label>
                          <input
                            type="text"
                            name="meta_title"
                            className="form-control"
                            id="meta_title"
                            required
                            {...register("meta_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="p_final_price">
                            Meta Description
                          </label>
                          <input
                            type="text"
                            name="meta_desc"
                            className="form-control"
                            id="meta_desc"
                            required
                            {...register("meta_desc")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="c_id_fk">Choose Category</label>
                          <select
                            className="form-control"
                            name="b_cid_fk"
                            {...register("b_cid_fk")}
                          >
                            {blogCategories &&
                              blogCategories.map((blogCategory) => (
                                <option value={blogCategory.bcat_id}>
                                  {blogCategory.bcat_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-4">
                        <label>Add Product Main Image</label>
                        <input
                          className="form-control"
                          type="file"
                          id="upload_image1"
                          name="upload_image1"
                          required
                          onChange={(e) => setBlogImg(e.target.files[0])}
                        />
                        <div id="uploaded_image1" className="ml-auto pt-2" />
                        <div
                          className="modal"
                          id="image_modal1"
                          data-backdrop="static"
                          data-keyboard="false"
                          tabIndex={-1}
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="col-lg-12 mx-auto">
                                  <div id="image_demo1" />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary crop_image1"
                                >
                                  Save and upload
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="p_description">
                            Long Description
                          </label>
                          <textarea
                            name="p_description"
                            id="p_description"
                            className="form-control"
                            rows={5}
                            required
                            defaultValue={""}
                            {...register("b_long_desc")}
                          />
                          {/* <TextEditor {...register("p_description")} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddNewBlog;
