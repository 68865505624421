import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="main-footer footer">
        <strong>
          Copyright © 2024
          <a href="https://bakumia.pk"> bakumia.pk</a>.
        </strong>
        All rights reserved.
      </footer>
    </>
  );
};

export default Footer;
