import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import { updateCity } from "../../services/userServices";

const UpdateCity = () => {
  const { city_id } = useParams();
  const [city, setCity] = useState([]);
  const [province, setProvince] = useState([]);

  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      city_id: city.city_id || "",
      city_name: city.city_name || "",
      province_id_fk: city.province_id_fk || "",
      is_active: city.is_active || "",
    },
  });

  useEffect(() => {
    setValue("city_id", city.city_id || "");
    setValue("city_name", city.city_name || "");
    setValue("province_id_fk", city.province_id_fk || "");
    setValue("is_active", city.is_active || "");
  }, [city]);

  useEffect(() => {
    apiClient
      .get(`/get-city/${city_id}`)
      .then((response) => setCity(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    apiClient
      .get("/get-province")
      .then((response) => setProvince(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const handleUpdateCity = (formData) => {
    updateCity(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          navigate("/cities");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding  city.");
      });
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card card-info card-outline">
              <div className="card-header ">
                <div className="row">
                  <div className="col-sm-8">
                    <h3 className="card-title">Edit City</h3>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <form onSubmit={handleSubmit(handleUpdateCity)}>
                    <div className="row" key={city.city_id}>
                      <div className="col-md-3">
                        <label>Choose Province</label>
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="province_id_fk"
                            required
                            {...register("province_id_fk")}
                            defaultValue={city.province_id_fk}
                          >
                            {province &&
                              province.map((item) => (
                                <option value={item.province_id}>
                                  {item.province_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label>City Name</label>
                        <div className="form-group">
                          <input
                            type="hidden"
                            name="city_id"
                            {...register("city_id")}
                            className="form-control"
                            defaultValue={city.city_id}
                            required
                          />
                          <input
                            type="text"
                            name="city_name"
                            className="form-control"
                            defaultValue={city.city_name}
                            required
                            {...register("city_name")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label>Status</label>
                        <select
                          className="form-control"
                          name="is_active"
                          required
                          defaultValue={city && city.is_active}
                          onChange={(e) =>
                            setValue("is_active", e.target.value)
                          }
                        >
                          <option value={0}>Not Active</option>
                          <option value={1}>Active</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label>Update</label>
                        <div className="form-group">
                          <button type="submit" className="btn btn-info">
                            <i className="fas fa-pencil-alt" /> Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateCity;
