import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient, { IMAGE_BASE_URL } from "../../utils/api-client";
import {
  updateProductSubcategory,
  updateProductSubcategoryImg,
} from "../../services/userServices";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const UpdateProductSubcategory = () => {
  const { s_cat_id } = useParams();
  const [subcategoryDetails, setSubcategoryDetails] = useState([]);
  const [productCategories, setPoductCategories] = useState([]);
  const [errors, setErrors] = useState("");

  const [subcategoryImg, setSubcategoryImg] = useState(null);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      p_sub_category_name: subcategoryDetails.p_sub_category_name || "",
      s_cat_title: subcategoryDetails.s_cat_title || "",
      s_cat_subtitle: subcategoryDetails.s_cat_subtitle || "",
      s_cat_short_desc: subcategoryDetails.s_cat_short_desc || "",
      s_cat_long_desc: subcategoryDetails.s_cat_long_desc || "",
      p_category_id_fk: subcategoryDetails.p_category_id_fk || "0",
      s_cat_id: subcategoryDetails.s_cat_id || "0",
    },
  });

  useEffect(() => {
    setValue(
      "p_sub_category_name",
      subcategoryDetails.p_sub_category_name || ""
    );
    setValue("s_cat_title", subcategoryDetails.s_cat_title || "");
    setValue("s_cat_subtitle", subcategoryDetails.s_cat_subtitle || "");
    setValue("s_cat_short_desc", subcategoryDetails.s_cat_short_desc || "");
    setValue("s_cat_long_desc", subcategoryDetails.s_cat_long_desc || "");
    setValue("pcat_image", subcategoryDetails.pcat_image || "");
    setValue("p_category_id_fk", subcategoryDetails.p_category_id_fk || "");
    setValue("s_cat_id", subcategoryDetails.s_cat_id || "");
  }, [subcategoryDetails]);

  useEffect(() => {
    apiClient
      .get(`/get-single-sub-cate/${s_cat_id}`)
      .then((response) => setSubcategoryDetails(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  useEffect(() => {
    apiClient
      .get("/get-product-cate")
      .then((response) => setPoductCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  }, []);

  const onSubmit = (formData) => {
    updateProductSubcategory(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating product subcategory.");
      });
  };

  const handleSubcategoryImgChange = () => {
    updateProductSubcategoryImg(s_cat_id, subcategoryImg)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating image.");
      });
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(handleSubcategoryImgChange)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Edit Sub Category Image</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-12 border">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Sub Category Image</label>
                              <input
                                className="form-control"
                                type="file"
                                id="upload_image1"
                                name="upload_image1"
                                defaultValue={
                                  subcategoryDetails &&
                                  subcategoryDetails.s_cat_image
                                }
                                onChange={(e) =>
                                  setSubcategoryImg(e.target.files[0])
                                }
                              />
                              <div
                                id="uploaded_image1"
                                className="ml-auto pt-2"
                              />
                              <div className="col-md-12">
                                <div id="previous_image" className="my-3">
                                  <img
                                    src={`${IMAGE_BASE_URL}/${
                                      subcategoryDetails &&
                                      subcategoryDetails.s_cat_image
                                    }`}
                                    height={100}
                                    className="img-rounded"
                                  />
                                </div>
                              </div>
                              <div
                                className="modal"
                                id="image_modal1"
                                data-backdrop="static"
                                data-keyboard="false"
                                tabIndex={-1}
                                role="dialog"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-body">
                                      <div className="col-lg-12">
                                        <div id="image_demo1" />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-primary crop_image1"
                                      >
                                        Save and upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-dark card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Edit Subcategory</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            name="p_sub_category_name"
                            className="form-control"
                            id="p_sub_category_name"
                            defaultValue={
                              subcategoryDetails &&
                              subcategoryDetails.p_sub_category_name
                            }
                            required
                            {...register("p_sub_category_name")}
                          />
                          <input
                            type="hidden"
                            name="s_cat_id"
                            className="form-control"
                            id="s_cat_id"
                            defaultValue={
                              subcategoryDetails && subcategoryDetails.s_cat_id
                            }
                            required
                            {...register("s_cat_id")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category-title">Title</label>
                          <input
                            type="text"
                            name="s_cat_title"
                            className="form-control"
                            id="s_cat_title"
                            defaultValue={
                              subcategoryDetails &&
                              subcategoryDetails.s_cat_title
                            }
                            required
                            {...register("s_cat_title")}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category-title">Subtitle</label>
                          <input
                            type="text"
                            name="s_cat_subtitle"
                            className="form-control"
                            id="s_cat_subtitle"
                            defaultValue={
                              subcategoryDetails &&
                              subcategoryDetails.s_cat_subtitle
                            }
                            required
                            {...register("s_cat_subtitle")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="input-6">Select Category</label>

                          <select
                            id="inputStatus"
                            className="form-control custom-select"
                            name="category_id_fk"
                            required
                            {...register("p_category_id_fk")}
                          >
                            {/* <option
                              value={
                                subcategoryDetails &&
                                subcategoryDetails.category &&
                                subcategoryDetails.category.p_category_id
                              }
                              hidden
                            >
                              {subcategoryDetails &&
                                subcategoryDetails.category &&
                                subcategoryDetails.category.p_category_name}
                            </option> */}

                            <option value="" hidden>
                              Select Category
                            </option>
                            {productCategories &&
                              productCategories.map((productCategory) => (
                                <option value={productCategory.p_category_id}>
                                  {productCategory.p_category_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="short-desc">Short Description</label>
                          <textarea
                            name="s_cat_short_desc"
                            id="short-desc"
                            className="form-control"
                            defaultValue={
                              subcategoryDetails &&
                              subcategoryDetails.s_cat_short_desc
                            }
                            {...register("s_cat_short_desc")}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="long-desc">Long Description</label>
                          <textarea
                            name="s_cat_long_desc"
                            className="form-control"
                            id="long-desc"
                            defaultValue={
                              subcategoryDetails &&
                              subcategoryDetails.s_cat_long_desc
                            }
                            {...register("s_cat_long_desc")}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="col-md-12 border">
                          <div className="row">
                            <div className="col-md-12">
                              <label>Product Image</label>
                              <input
                                className="form-control"
                                type="file"
                                id="upload_image1"
                                name="upload_image1"
                              />
                              <div
                                id="uploaded_image1"
                                className="ml-auto pt-2"
                              />
                              <div className="col-md-12">
                                <div id="previous_image" className="my-3">
                                  <img
                                    src={`https://bakend-api-bakumia.devifysolutions.pk/public/${
                                      subcategoryDetails &&
                                      subcategoryDetails.s_cat_image
                                    }`}
                                    height={100}
                                    className="img-rounded"
                                  />
                                </div>
                              </div>
                              <div
                                className="modal"
                                id="image_modal1"
                                data-backdrop="static"
                                data-keyboard="false"
                                tabIndex={-1}
                                role="dialog"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-body">
                                      <div className="col-lg-12">
                                        <div id="image_demo1" />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-primary crop_image1"
                                      >
                                        Save and upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button
                        type="submit"
                        className="btn btn-dark btn-custom submit"
                      >
                        <i className="fa fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProductSubcategory;
