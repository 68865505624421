import React, { useEffect, useState } from "react";
import apiClient from "../../utils/api-client";
import { useForm } from "react-hook-form";
import { addBlogCategory } from "../../services/userServices";
import { toast } from "react-toastify";
import { updateBlogCatrogry } from "./../../services/userServices";

const BlogCategories = () => {
  const [blogsCategories, setBlogsCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [errors, setErrors] = useState([]);

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    apiClient
      .get("/get-all-blog-cat")
      .then((response) => setBlogsCategories(response.data.data))
      .catch((err) => setErrors(err.message));
  };

  const handleEditClick = (category) => {
    setSelectedCategory(category);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
  };

  const deactivateCategory = (categoryId) => {
    apiClient
      .post(`/update-blog-cat-status?bcat_id=${categoryId}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchCategories();
      })
      .catch((error) => {
        toast.error("Error try again!", error);
      });
  };

  const handleAddCategorySubmit = (formData) => {
    addBlogCategory(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchCategories();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while adding blog category.");
      });
  };

  const handleUpdateCategorySubmit = (formData) => {
    updateBlogCatrogry(formData)
      .then((response) => {
        if (response && response.data.status === true) {
          toast.success(response.data.message);
          fetchCategories();
          setSelectedCategory(null);
        } else {
          toast.error(response.data.message);
          setSelectedCategory(null);
        }
      })
      .catch((err) => {
        toast.error("An error occurred while updating blog category.");
      });
  };

  console.log(selectedCategory);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-right mb-3 ">
              <button
                type="button"
                className="btn btn-success btn-custom btn-sm"
                data-toggle="modal"
                data-target="#modal-default"
              >
                <i className="fas fa-plus" /> Add New category
              </button>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-info">
                  <div className="row">
                    <div className="col-md-9">
                      <h3 className="card-title m-1"> Articles Categories</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Category Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogsCategories &&
                        blogsCategories.map((item) => (
                          <tr key={item.bcat_id}>
                            <td className="project-actions">
                              <button
                                className="btn btn-info btn-sm btn-custom"
                                data-toggle="modal"
                                data-target="#modal-default1"
                                onClick={() => setSelectedCategory(item)}
                              >
                                <i className="fas fa-pencil-alt" /> Edit
                              </button>
                              {item.is_active > 0 ? (
                                <button
                                  className="btn btn-danger btn-custom btn-sm m-1"
                                  onClick={() =>
                                    deactivateCategory(item.bcat_id)
                                  }
                                >
                                  <i className="fas fa-trash"></i> Deavtive
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-custom btn-sm m-1"
                                  onClick={() =>
                                    deactivateCategory(item.bcat_id)
                                  }
                                >
                                  <i className="fas fa-plus"></i> Active
                                </button>
                              )}
                            </td>
                            <td>{item.bcat_name}</td>
                            <td>
                              {item.is_active > 0 ? (
                                <span className="badge badge-success p-2">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-danger p-2">
                                  Deactive
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/*Add New Category Modal */}
            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleSubmit(handleAddCategorySubmit)}>
                    <div className="modal-header border-0 bg-info">
                      <h4 className="modal-title">Enter Category Name</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <input
                          type="text"
                          name="bcat_name"
                          className="form-control"
                          id="bcat_name"
                          {...register("bcat_name")}
                          required
                        />
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Edit Category Modal */}
            <div
              className="modal fade"
              id="modal-default1"
              data-backdrop="static"
              tabIndex="-1"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleSubmit(handleUpdateCategorySubmit)}>
                    <div className="modal-header border-0 bg-info">
                      <h4 className="modal-title">Edit Category</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setSelectedCategory(null)}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="modal-body">
                        {selectedCategory && (
                          <div className="form-group">
                            <label htmlFor="">Current Name</label>
                            <input
                              type="text"
                              className="form-control mb-3"
                              value={selectedCategory.bcat_name}
                              disabled
                            />

                            <label htmlFor="">Set New Name</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("bcat_name")} // Registering the input field
                            />
                            <input
                              type="hidden"
                              name="bcat_id"
                              id="bcat_id"
                              value={selectedCategory.bcat_id}
                              {...register("bcat_id")} // Registering the input field
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between border-0">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                        onClick={() => setSelectedCategory(null)}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-info btn-custom">
                        <i className="fas fa-paper-plane" /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogCategories;
